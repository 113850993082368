<template>
    <div class="container-xxl py-5">
        <div class="container-fluid dashboard-container ">
            <div class="container mt-3" style="border-radius: 10px;">
                <div class="row">
                    <aside class="col-lg-3 p-1 sidebar">
                        
                        <div class="card shadow mb-3" style="border-color: #2d1f65;">
                            <div class="card-header bg-custom text-white">เมนู</div>
                            <div class="card-body">
                                <ul class="nav flex-column">
                                    <li class="nav-item">     
                                        <button 
                                            :class="['btn custom-outline-btn d-flex align-items-center justify-content-center p-2 w-100 rounded mb-2 mt-2', { 'active': selectedMenu === 'AddProperty2' }]"
                                            @click="showComponent('AddProperty2')">
                                            <i class="fa-solid fa-plus me-2"></i>
                                            <span>ลงประกาศขาย</span>
                                        </button>
                                        <button 
                                            :class="['btn custom-outline-btn d-flex align-items-center justify-content-center p-2 w-100 rounded mb-2 mt-2', { 'active': selectedMenu === 'ListProperty' }]"
                                            @click="showComponent('ListProperty')">
                                            <i class="fa-solid fa-list-ul me-2"></i>
                                            <span>รายการประกาศ</span>
                                        </button>
                                        <button 
                                            :class="['btn custom-outline-btn d-flex align-items-center justify-content-center p-2 w-100 rounded mb-2 mt-2', { 'active': selectedMenu === 'ShopPage' }]"
                                            @click="showComponent('ShopPage')">
                                            <i class="fa-regular fa-solid fa-shop me-2"></i>
                                            <span>ร้านค้าโปรโมชัน</span>
                                        </button>
                                        <button 
                                            :class="['btn custom-outline-btn d-flex align-items-center justify-content-center p-2 w-100 rounded mb-2 mt-2', { 'active': selectedMenu === 'CreditTopup' }]"
                                            @click="showComponent('CreditTopup')">
                                            <i class="fa-regular fa-credit-card me-2"></i>
                                            <span>เติมเครดิต</span>
                                        </button>
                                        <button 
                                            :class="['btn custom-outline-btn d-flex align-items-center justify-content-center p-2 w-100 rounded mb-2 mt-2', { 'active': selectedMenu === 'SendingSlip' }]"
                                            @click="showComponent('SendingSlip')">
                                            <i class="fa-solid fa-file-invoice-dollar me-2"></i>
                                            <span>แจ้งการโอน</span>
                                        </button>
                                        <!-- <button class="btn btn-outline-primary d-flex align-items-center justify-content-center p-2 w-100 rounded mb-2 mt-2">
                                            <i class="fa-solid fa-chart-simple me-2"></i>
                                            <span>ภาพรวมสถิติทั้งหมด</span>
                                        </button>
                                        <button class="btn btn-outline-primary d-flex align-items-center justify-content-center b p-2 w-100 rounded mb-2 mt-2 ">
                                            <i class="fa-regular fa-clock me-2"></i>
                                            <span>ตั้งเวลาเลื่อนประกาศ</span>
                                        </button> -->
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="card shadow mb-3" style="border-color: #2d1f65;">
                            <div class="card-header bg-custom text-white">โปรไฟล์</div>
                                <div class="card-body">
                                    <div class="container">
                                        <div class="row justify-content-center">
                                            <div class="col-10 mb-3" id="imgProfile">
                                                <div class="text-center">
                                                    <img src="/img/user-profile-icon.png" alt="Profile" class="rounded-circle" style="width: 80px; height: 80px; object-fit: cover; margin-bottom: 1.5rem; margin-top: 1rem;">
                                                    <p>{{ userName }}</p>
                                                </div>
                                                <div class="container mt-3">
                                                    <hr>
                                                    <div class="row rounded" style="background-color: #2d1f6525;">
                                                        <div class="col-12 d-flex align-items-center justify-content-center rounded mt-3">
                                                            <span>{{ Name ? Name : userName }}</span>
                                                        </div>
                                                        <div class="col-12 d-flex align-items-center justify-content-center rounded">
                                                            <span class="text-shadow" style="color: #aa8c15;">Credit : {{ myCredit }}
                                                                <img src="img/coin-img.webp" alt="coin" class="rounded-circle" style="width: 30px; height: 30px; object-fit: cover; margin-bottom: 1.5rem; margin-top: 1rem;">
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <hr>
                                                    <div class="row">
                                                        <div class="col-12 d-flex align-items-center justify-content-center bg-primary text-white p-2 rounded mb-2 mt-2">
                                                            <i class="fa-solid fa-mobile-screen-button me-2"></i>
                                                            <span style="font-size: 14px;">{{ Tel ? Tel : 'ไม่ระบุ' }}</span>
                                                        </div>
                                                        <div class="col-12 d-flex align-items-center justify-content-center custom-bg-bright-green text-white p-2 rounded mb-2 mt-2">
                                                            <i class="fa-brands fa-line me-2"></i>
                                                            <span style="font-size: 14px;">{{ Line ? Line : 'ไม่ระบุ' }}</span>
                                                        </div>
                                                        <div class="col-12 d-flex align-items-center justify-content-center bg-secondary text-white p-2 rounded mb-2 mt-2 email-text">
                                                            <i class="fa-solid fa-envelope me-2"></i>
                                                            <span style="font-size: 14px;">{{ Email ? Email : 'ไม่ระบุ' }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr>
                                                <div class="mt-4 d-flex justify-content-center">
                                                    <button 
                                                        :class="['btn custom-outline-btn d-flex align-items-center justify-content-center p-2 rounded mb-2 mt-2', { 'active': selectedMenu === 'UserInfoPage' }]"
                                                        @click="showComponent('UserInfoPage')">
                                                        <i class="fa-regular fa-pen-to-square me-2"></i>
                                                        <span>แก้ไขโปรไฟล์</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        <div class="card shadow mb-3" style="border-color: #2d1f65;">
                            <div class="card-body">
                                <div class="package-info mt-4">
                                    <h5>Package ปัจจุบัน</h5>
                                    <p>ฟรี ไม่มีวันหมดอายุ</p>
                                    <div class="progress mb-2">
                                    <div class="progress-bar" role="progressbar" style="width: 100%;" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">0/999</div>
                                </div>
                                    <p class="m-0 mt-3 text-success">โปรโมชันเปิดเว็บใหม่</p>
                                    <p class="m-0 mb-1 text-success">โพสได้ไม่จำจัด 30 วัน</p>
                                </div>
                            </div>
                        </div>
                    </aside>
                    <!-- Main Content Area -->
                    <main class="col-lg-9 main-content p-lg-2" ref="mainContent">
                        <component
                            :is="currentComponent"
                            :ref="currentComponentRef"
                            :property-id="selectedPropertyId"
                            @edit-property="handleEditProperty"
                            @cancel-edit="handleCancelEdit"
                        ></component>
                    </main>
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>

import AddProperty2 from './AddProperty2.vue';
import ListProperty from './ListProperty.vue';
import EditProperty from './EditProperty.vue';
import CreditTopup from './CreditTopup.vue';
import ShopPage from './ShopPage.vue';
import SendingSlip from './SendingSlip.vue';
import UserInfoPage from './UserInfoPage.vue';

import { mapGetters } from 'vuex';

  export default {
    props: {
        initialComponent: {
            type: String,
            default: 'ListProperty'
        }
    },
    data() {
      return {
        items: [
          {
            id: 1,
            image: 'https://via.placeholder.com/150',
            title: 'ขายที่ดินถูกมาก',
            description: 'ที่ดินพร้อมโฉนด ใกล้แหล่งชุมชน',
            price: '2,500,000 บาท'
          },
          {
            id: 2,
            image: 'https://via.placeholder.com/150',
            title: 'บ้านพร้อมที่ดิน',
            description: 'บ้านในโครงการคุณภาพ มีสิ่งอำนวยความสะดวกครบครัน',
            price: '3,000,000 บาท'
          },
          // Add more items as needed
        ],
        currentComponent: this.$route.query.component || 'ListProperty', // กำหนดค่าเริ่มต้นจาก query หรือ ListProperty
        selectedMenu: this.$route.query.component || 'ListProperty',    // กำหนดค่า selectedMenu
        selectedPropertyId: null,
      };
    },
    components: {
        ListProperty,
        AddProperty2,
        EditProperty,
        CreditTopup,
        ShopPage,
        SendingSlip,
        UserInfoPage
    },
    methods: {
        showComponent(componentName) {
            if (componentName === 'SendingSlip') {
                this.$router.push({ name: 'DashboardPage', query: { component: 'SendingSlip' } });
                this.selectedMenu = 'SendingSlip';
                return;
            }
            const currentRef = this.$refs[this.currentComponentRef];
            if (this.currentComponent === 'EditProperty' && currentRef) {
                currentRef.showUnsavedChangesModal(() => {
                    this.$router.push({ name: 'DashboardPage', query: { component: componentName } });
                });
            } else if (this.currentComponent === 'AddProperty2' && currentRef) {
                currentRef.$options.beforeRouteLeave.call(
                    currentRef,
                    { name: 'DashboardPage', query: { component: componentName } },
                    this.$route,
                    (confirmation) => {
                        if (confirmation !== false) {
                            this.$router.push({ name: 'DashboardPage', query: { component: componentName } });
                        }
                    }
                );
            } else {
                this.$router.push({ name: 'DashboardPage', query: { component: componentName } });
            }
            this.selectedMenu = componentName;
        },
        scrollToMainContent() {
            const mainContent = this.$refs.mainContent;
            if (mainContent) {
                mainContent.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start' // เลื่อนไปจุดเริ่มต้นของ <main>
                });
            }
        },
        handleEditProperty(propertyId) {
            this.currentComponent = 'EditProperty';
            this.selectedMenu = 'EditProperty';
            this.selectedPropertyId = propertyId;
        },
        handleCancelEdit() {
            this.currentComponent = 'ListProperty';
            this.selectedMenu = 'ListProperty';
            this.selectedPropertyId = null;
        },
        // handleSlipSent() {
        //     this.currentComponent = 'SendingSlip';
        //     this.selectedMenu = 'SendingSlip';
        // },
    },
    computed: {
        ...mapGetters(['isLoggedIn', 'userName', 'tel', 'line', 'name', 'credit']),
        myCredit() {
            return this.$store.state.credit;
        },
        currentComponentRef() {
            // กำหนดชื่อ ref สำหรับ Component ปัจจุบัน
            return this.currentComponent + 'Component';
        },
        Name() {
            return this.$store.getters.name;
        },
        Email() {
            return this.$store.getters.email;
        },
        Tel() {
            return this.$store.getters.tel;
        },
        Line() {
            return this.$store.getters.line;
        },
    },
    watch: {
        '$route.query.component'(newComponent) {
            if (newComponent) {
            this.currentComponent = newComponent;
            this.selectedMenu = newComponent;
            this.$nextTick(() => { // เลื่อนหลัง DOM อัปเดต
                setTimeout(() => {  // เพิ่ม setTimeout เพื่อให้แน่ใจว่า DOM โหลดเสร็จ
                    this.scrollToMainContent();
                }, 150);
            });
            } else {
            this.currentComponent = 'ListProperty';
            this.selectedMenu = 'ListProperty';
            }
        }
    },
  };
  </script>
  
  <style scoped>
  .dashboard-container {
    padding: 0;
  }
  
  .sidebar {
    min-height: 100vh;
    /* Ensure the sidebar takes up the full height of the screen */
  }
  
  .main-content {
    padding: 4px;

  }

  @media (max-width: 576px) {
        .email-text span {
            font-size: 13px; /* Adjust the font size as needed */
        }
    }
  </style>
  