// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import { WOW } from 'wowjs';
import store from '@/store'; // นำเข้า store จาก Vuex
//import Home from '../views/HomePage.vue';

import ErrorPage404 from '../views/ErrorPage404.vue';
import RegisterPage from '../views/RegisterPage.vue';
import LoginPage from '../views/LoginPage.vue';
import PropertyPage from '../views/PropertyPage.vue';
import PropertyDetail from '../views/PropertyDetail.vue';
import DashboardPage from '../views/Dashboard/DashboardPage.vue';
import PropertyListPage from '@/views/PropertyListPage.vue';
import EditProperty from '../views/Dashboard/EditProperty.vue';
import AddProperty2 from '@/views/Dashboard/AddProperty2.vue';
import ContactPage from '@/views/ContactPage.vue';
import UserInfoPage from '@/views/Dashboard/UserInfoPage.vue';
import ForgotPassword from '@/views/ForgotPassword.vue'
import ResetPassword from '@/views/ResetPassword.vue'

const routes = [
  { path: '/', component: PropertyPage },
  // { path: '/AddProperty', component: AddProperty, meta: { requiresAuth: true }, },
  
  { path: '/register', component: RegisterPage},
  { path: '/login', component: LoginPage},
  { path: '/property', component: PropertyPage},
  { path: '/contact', 
    component: ContactPage,
    name: 'ContactPage',
  },
  {
    path: '/propertyDetail/:id',
    name: 'PropertyDetail', // เพิ่มบรรทัดนี้เพื่อกำหนดชื่อเส้นทาง
    component: PropertyDetail
  },
  {
    path: '/add-property',
    name: 'AddProperty2',
    component: AddProperty2,
    meta: { requiresAuth: true }
  },
  {
    path: '/edit-property/:id',
    name: 'EditProperty',
    component: EditProperty, // หรือ path ไปยัง component ของคุณ
    props: true
  },
  {
    path: '/user-profile',
    name: 'UserProfile',
    component: UserInfoPage,
  },
  {
    path: '/dashboardPage',
    name: 'DashboardPage',
    component: DashboardPage,
    meta: { requiresAuth: true },
    props: route => ({ initialComponent: route.query.component || 'ListProperty' })
  },
  { path: '/propertyList', name: 'PropertyListPage', component: PropertyListPage},
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword
  },
  { path: '/:pathMatch(.*)*', component: ErrorPage404 },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  }
});
// router.beforeEach((to, from, next) => {
//   const isLoggedIn = store.getters.isLoggedIn; // ตรวจสอบสถานะล็อกอินจาก Vuex

//   if (to.matched.some(record => record.meta.requiresAuth) && !isLoggedIn) {
//     // ถ้าหน้านั้นต้องการล็อกอินแต่ผู้ใช้ยังไม่ได้ล็อกอิน
//     next('/login');
//   } else {
//     // หน้าที่ไม่ต้องล็อกอินสามารถเข้าได้เลย
//     next();
//   }
// });
// router.beforeEach(async (to, from, next) => {
//   // ถ้ากำลังนำทางไปที่ /login หรือ /register ไม่ต้องเช็คการยืนยันตัวตน
//   if (to.path === '/login' || to.path === '/register') {
//     next();
//   } else {
//     await store.dispatch('checkAuth');

//     if (to.matched.some(record => record.meta.requiresAuth)) {
//       if (!store.getters.isLoggedIn) {
//         next('/login');
//       } else {
//         next();
//       }
//     } else {
//       next();
//     }
//   }
// });

// router.beforeEach(async (to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     await store.dispatch('checkAuth');
//     if (!store.getters.isLoggedIn) {
//       next('/login');
//     } else {
//       next();
//     }
//   } else {
//     next();
//   }
// });
router.beforeEach(async (to, from, next) => {
  await store.dispatch('checkAuth'); // ตรวจสอบสถานะการล็อกอินของผู้ใช้เสมอ

  const isLoggedIn = store.getters.isLoggedIn;

  // ป้องกันผู้ใช้ที่ล็อกอินแล้วเข้าถึง /login และ /register
  if (isLoggedIn && (to.path === '/login' || to.path === '/register' || to.path === '/forgot-password' || to.path === '/reset-password')) {
    next('/'); // นำทางไปที่หน้าแดชบอร์ดหรือหน้าที่คุณต้องการ
  } else if (to.matched.some(record => record.meta.requiresAuth)) {
    // หากเส้นทางต้องการการตรวจสอบสิทธิ์
    if (!isLoggedIn) {
      next('/'); // นำทางไปที่หน้าเข้าสู่ระบบ
    } else {
      next(); // อนุญาตให้เข้าถึงเส้นทางนั้น
    }
  } else {
    next(); // อนุญาตให้เข้าถึงเส้นทางนั้น
  }
});
/* eslint-disable */ 
router.afterEach((to, from) => {
  // re-initialize WOW.js เมื่อเปลี่ยนหน้า
  new WOW().init();
});

export default router;
