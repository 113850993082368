<template>
    <div class="container-xxl py-5">
        <div class="container-fluid dashboard-container ">
            <div class="container mt-3" style="border-radius: 10px;">
                <div class="row">
                    <aside class="col-lg-3 col-md-3 p-1 sidebar">
                        <div class="card shadow mb-3" style="overflow: visible; border-color: #2d1f65;">
                            <div class="card-header bg-custom text-white">
                                ค้นหาจากที่อยู่
                            </div>
                            <div class="card-body">
                                <div class="package-info my-3">
                                    <RegionProvinceSelector
                                        ref="regionProvinceSelector"
                                        @locationSelected="updateLocation"
                                        :initialRegion="searchFilters.Region"
                                        :initialProvince="searchFilters.Province"
                                        :initialAmphur="searchFilters.Amphur"
                                        :initialTambon="searchFilters.Tambon"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="card shadow mb-3" style="overflow: visible; border-color: #2d1f65;">
                            <div class="card-header bg-custom text-white">
                                ค้นหาจากราคา
                            </div>
                            <div class="card-body">
                                <div class="package-info my-3">
                                     <!-- Price Range Dropdown -->
                                    <div class="dropdown border rounded mt-3" :class="{ show: dropdownVisiblePrice }" ref="priceDropdown">
                                        <button
                                            class="btn custom-outline-btn dropdown-toggle w-100 text-center d-flex justify-content-between align-items-center"
                                            type="button"
                                            @click="togglePriceDropdown"
                                        >
                                        <div class="w-100 text-center">
                                            {{ priceRangeText }}
                                        </div>
                                        </button>
                                        <ul class="dropdown-menu animated-dropdown" :class="{ show: dropdownVisiblePrice }">
                                            <li class="px-3 py-2">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <label for="minPrice" class="text-center w-100 mb-1" style="font-size: 14px;">ราคาต่ำสุด</label>
                                                        <div class="input-group">
                                                            <input
                                                                type="text"
                                                                id="minPrice"
                                                                class="form-control"
                                                                placeholder="ราคาต่ำสุด"
                                                                v-model="tempMinPrice"
                                                                @input="formatMinPrice"
                                                                @focus="addFocusStyle('minPriceInput')" 
                                                                @blur="removeFocusStyle('minPriceInput')"
                                                                ref="minPriceInput"
                                                            />
                                                            <span 
                                                                class="input-group-text clear-icon" 
                                                                @click="clearMinPrice" 
                                                                ref="minPriceInput" 
                                                                style="cursor: pointer;">
                                                                <i class="fas fa-times mx-2"></i>
                                                            </span>
                                                            
                                                            <span class="input-group-text text-thin">฿</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 mt-2">
                                                        <label for="maxPrice" class="text-center w-100 mb-1" style="font-size: 14px;">ราคาสูงสุด</label>
                                                        <div class="input-group">
                                                            <input
                                                                type="text"
                                                                id="maxPrice"
                                                                class="form-control"
                                                                placeholder="ราคาสูงสุด"
                                                                v-model="tempMaxPrice"
                                                                @input="formatMaxPrice"
                                                                @focus="addFocusStyle('maxPriceInput')" 
                                                                @blur="removeFocusStyle('maxPriceInput')"
                                                                ref="maxPriceInput"
                                                            />
                                                            <span 
                                                                class="input-group-text clear-icon" 
                                                                @click="clearMaxPrice" 
                                                                ref="maxPriceInput" 
                                                                style="cursor: pointer;">
                                                                <i class="fas fa-times mx-2"></i>
                                                            </span>
                                                            <span class="input-group-text text-thin">฿</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 text-center my-3">
                                                        <button class="btn custom-outline-btn" @click="confirmPriceRange">ยืนยัน</button>
                                                        <button class="btn btn-outline-secondary ms-2" @click="cancelPriceRange">ยกเลิก</button>
                                                    </div>
                                                    
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <!-- End Price Range Dropdown -->
                                </div>
                            </div>
                        </div>
                        <div class="card shadow mb-3" style="overflow: visible; border-color: #2d1f65;">
                            <div class="card-header bg-custom text-white">
                                ล้างค่าการค้นหา
                            </div>
                            <div class="card-body">
                                    <div class="text-center my-3">
                                        <button class="btn btn-outline-danger" @click="clearFilters">ล้างค่าทั้งหมด</button>
                                    </div>
                            </div>
                        </div>
                        <div class="card shadow mb-3" style="overflow: visible; border-color: #2d1f65;">
                            <div class="card-header bg-custom text-white">
                                ทำเลยอดนิยม
                            </div>
                            <div class="card-body">
                                <div class="package-info mt-4 mb-4">
                                    <p style="font-size: 16px;">
                                        <a href="javascript:void(0)" @click="filterByLocation('ภาคกลาง','กรุงเทพมหานคร')">กรุงเทพมหานครฯ</a>
                                    </p>
                                    <p style="font-size: 16px;">
                                        <a href="javascript:void(0)" @click="filterByLocation('ภาคเหนือ','เชียงใหม่')">เชียงใหม่</a>
                                    </p>
                                    <p style="font-size: 16px;">
                                        <a href="javascript:void(0)" @click="filterByLocation('ภาคตะวันออก','ชลบุรี')">ชลบุรี</a>
                                    </p>
                                    <p style="font-size: 16px;">
                                        <a href="javascript:void(0)" @click="filterByLocation('ภาคตะวันออกเฉียงเหนือ','ขอนแก่น')">ขอนแก่น</a>
                                    </p>
                                    <p style="font-size: 16px;">
                                        <a href="javascript:void(0)" @click="filterByLocation('ภาคกลาง','สมุทรปราการ')">สมุทรปราการ</a>
                                    </p>
                                    <p style="font-size: 16px;">
                                        <a href="javascript:void(0)" @click="filterByLocation('ภาคกลาง','นนทบุรี')">นนทบุรี</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </aside>
                    <!-- Main Content Area -->
                    <main class="col-lg-9 col-md-9 main-content">
                        <!-- Top Navigation -->
                        <div class="top-nav d-flex flex-column flex-sm-row justify-content-between align-items-center text-center py-2 mb-3 ms-0 ms-md-3">
                            <h5>รายการประกาศทั้งหมด</h5>
                            <div class="filters d-flex">
                                <select
                                    class="form-select me-2"
                                    style="border-color: #2d1f65;"
                                    v-model="searchFilters.Category"
                                    @change="updateFilters"
                                >
                                    <option value="">ประเภทอสังหา</option>
                                    <option v-for="category in categories" :key="category" :value="category">
                                        {{ category }}
                                    </option>
                                </select>
                                <select
                                    class="form-select"
                                    style="border-color: #2d1f65;"
                                    v-model="searchFilters.PropertyType"
                                    @change="updateFilters"
                                >
                                    <option value="">ประเภทประกาศ</option>
                                    <option
                                        v-for="propertyType in propertyTypes"
                                        :key="propertyType"
                                        :value="propertyType"
                                    >
                                        {{ propertyType }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <!-- List of Items -->
                        <div class="container">
                            <div class="row item-list">
                                <div
                                    class="col-12 col-sm-12 col-md-4 mb-4 property-item-custom"
                                    v-for="item in paginatedItems"
                                    :key="item._id"
                                    @click="navigateToDetail(item._id)"
                                >
                                <div class="card-property shadow rounded shadow" id="carditems" style="border: 1px solid #2d1f65;">
                                    <div class="content-wrapper">
                                        <img
                                            :src="item.images[0]?.url || 'https://via.placeholder.com/150'"
                                            alt=""
                                            class="responsive-image rounded-top card-img-top zoom"
                                        />
                                        <div 
                                            v-if="item.SelectedCard === 'exclusive'" 
                                            class="text-white position-absolute start-0 top-0 py-1 px-3" 
                                            style="background: #efbf04;"
                                        >
                                        Exclusive
                                        </div>
                                        <div 
                                            v-if="item.SelectedCard === 'premium'" 
                                            class="text-white position-absolute start-0 top-0 py-1 px-3" 
                                            style="background: #310bd8;"
                                        >
                                        PREMIUM
                                        </div>
                                        <div 
                                            v-if="item.SelectedCard === 'hot'" 
                                            class="text-white position-absolute start-0 top-0 py-1 px-3" 
                                            style="background: #c92a0d;"
                                        >
                                        HOT
                                        </div>
                                    </div>
                                    <div class="card-body-property">
                                        <div class="badges text-center text-md-start mb-2">
                                            <span class="badge bg-custom me-2" style="font-weight: 400;">{{ item.PropertyType }}</span>
                                            <span class="badge bg-custom me-2" style="font-weight: 400;">{{ item.Category }}</span>
                                        </div>
                                        <h6 class="text-custom" style="font-size: 15px;">{{ shortenTitle(item.Title) }}</h6>
                                        <p style="font-size: 13px;">
                                            <i class="fa fa-map-marker-alt custom-icon-color me-2" ></i>
                                            {{ item.Location?.Province || 'จังหวัด' }} {{ item.Location?.Amphur || 'อำเภอ' }} {{ item.Location?.Tambon || 'ตำบล' }}
                                        </p>
                                        <h5 v-if="item.PropertyType === 'ขาย'" class="price-text text-custom mb-3">฿ {{ item.Price.toLocaleString() }} บาท</h5>
                                        <h5 v-else class="price-text text-custom mb-3">฿ {{ item.Price.toLocaleString() }}/เดือน</h5>
                                    </div>
                                    <div v-if="item.Category === 'ที่ดิน'" class="d-flex border-top">
                                        <small class="flex-fill text-center border-end py-2" style="color: #310bd8;">
                                            <i class="fa fa-solid fa-house-chimney text-custom me-1"></i>
                                            {{ item.Category || 'ประเภท' }}
                                        </small>
                                        <small class="flex-fill text-center py-2" style="color: #310bd8;">
                                            <i class="fa fa-ruler-combined text-custom me-1"></i>
                                            {{ item.Scale?.ScaleRai || '0' }}-{{ item.Scale?.ScaleNgan || '0' }}-{{ item.Scale?.ScaleWah || '0' }} ไร่
                                        </small>
                                    </div>
                                    <div v-else class="d-flex border-top">
                                        <small class="flex-fill text-center border-end py-2" style="color: #310bd8;">
                                            <i class="fa fa-solid fa-house-chimney text-custom me-1"></i>
                                            {{ item.Category || 'ประเภท' }}
                                        </small>
                                        <small class="flex-fill text-center border-end py-2" style="color: #310bd8;">
                                            <i class="fa fa-bed text-custom me-1"></i>
                                            {{ item.houseDetails?.bedrooms || '0' }} นอน
                                        </small>
                                        <small class="flex-fill text-center py-2" style="color: #310bd8;">
                                            <i class="fa fa-bath text-custom me-1"></i>
                                            {{ item.houseDetails?.bathrooms || '0' }} น้ำ
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div v-if="filteredItems.length === 0" class="text-center mt-4">
                                <p>ไม่พบประกาศ</p>
                            </div>
                        </div>
                    </div>
                    <!-- End List of Items -->
                     
                    <!-- Pagination Controls -->
                    <div v-if="filteredItems.length > 20" class="pagination-controls d-flex justify-content-center mt-4">
                        <button class="btn custom-outline-btn me-2" @click="previousPage" :disabled="currentPage === 1">
                            ก่อนหน้า
                        </button>
                        
                        <div class="page-numbers mx-2">
                            <button
                                v-for="page in totalPages"
                                :key="page"
                                @click="goToPage(page)"
                                :class="['btn', page === currentPage ? 'custom-btn' : 'custom-outline-btn', 'mx-1']"
                            >
                                {{ page }}
                            </button>
                        </div>

                        <button class="btn custom-outline-btn ms-2" @click="nextPage" :disabled="currentPage === totalPages">
                            ถัดไป
                        </button>
                    </div>

                    </main>
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import axios from '@/axios.js';
  import RegionProvinceSelector from './RegionProvinceSelector.vue';

  export default {
    name: 'PropertyListPage',
    components: {
        RegionProvinceSelector,
    },
    data() {
      return {
        isFetching: false,
        items: [],
        filteredItems: [],
        initialized: false, // เพิ่มตัวแปรนี้

        // Pagination variables
        currentPage: 1,
        itemsPerPage: 20,

        // ตัวแปรสำหรับเก็บค่าการค้นหา
        searchFilters: {
            Category: '',
            PropertyType: '',
            Region: '',
            Province: '',
            Amphur: '',
            Tambon: '',
            MinPrice: '',
            MaxPrice: '',
        },
        toChildcomponent: {
            Region: null,
            Province: null,
        },
        HotLocationRegion: {
            bkk: {
                "id": 1,
                "name_th": "กรุงเทพมหานคร",
                "name_en": "Bangkok",
                "geography_id": 2,
                "created_at": "9/8/2019 03:33:09",
                "updated_at": "16/5/2022 06:31:03",
                "deleted_at": null
            },
            cnx: {
                "id": 38,
                "name_th": "เชียงใหม่",
                "name_en": "Chiang Mai",
                "geography_id": 1,
                "created_at": "9/8/2019 03:33:09",
                "updated_at": "16/5/2022 06:31:03",
                "deleted_at": null
            },
            chn: {
                "id": 11,
                "name_th": "ชลบุรี",
                "name_en": "Chon Buri",
                "geography_id": 5,
                "created_at": "9/8/2019 03:33:09",
                "updated_at": "16/5/2022 06:31:03",
                "deleted_at": null
            },
            kkn: {
                "id": 28,
                "name_th": "ขอนแก่น",
                "name_en": "Khon Kaen",
                "geography_id": 3,
                "created_at": "9/8/2019 03:33:09",
                "updated_at": "16/5/2022 06:31:03",
                "deleted_at": null
            },
            smk: {
                "id": 2,
                "name_th": "สมุทรปราการ",
                "name_en": "Samut Prakan",
                "geography_id": 2,
                "created_at": "9/8/2019 03:33:09",
                "updated_at": "16/5/2022 06:31:03",
                "deleted_at": null
            },
            ntb: {
                "id": 3,
                "name_th": "นนทบุรี",
                "name_en": "Nonthaburi",
                "geography_id": 2,
                "created_at": "9/8/2019 03:33:09",
                "updated_at": "16/5/2022 06:31:03",
                "deleted_at": null
            },
        },
        HotLocationProvince: {
            bkk:{
                "id": 2,
                "name": "ภาคกลาง"
            },
            cnx: {
                "id": 1,
                "name": "ภาคเหนือ"
            },
            chn: {
                "id": 5,
                "name": "ภาคตะวันออก"
            },
            kkn: {
                "id": 3,
                "name": "ภาคตะวันออกเฉียงเหนือ"
            },
            smk:{
                "id": 2,
                "name": "ภาคกลาง"
            },
            ntb:{
                "id": 2,
                "name": "ภาคกลาง"
            },
        },
        tempMinPrice: '', // ตัวแปรชั่วคราวสำหรับเก็บค่าราคาต่ำสุดที่ผู้ใช้กรอก
        tempMaxPrice: '', // ตัวแปรชั่วคราวสำหรับเก็บค่าราคาสูงสุดที่ผู้ใช้กรอก
        dropdownVisiblePrice: false,
        categories: ['บ้าน', 'คอนโด', 'ที่ดิน', 'ทาวน์โฮม', 'อาคารพาณิชย์', 'อสังหาอื่นๆ'], // เพิ่มประเภทอสังหา
        propertyTypes: ['ขาย', 'เช่า'], // เพิ่มประเภทประกาศ
      };
    },
    created() {
        this.applyFiltersFromQuery();
        this.checkPropertyTypeFromRoute();
        this.initialized = true; // ตั้งค่าเป็น true หลังจากโหลดข้อมูลเสร็จ
        //this.fetchProperties();
    },
    computed: {
        priceRangeText() {
            const minPriceValue = this.tempMinPrice ? Number(this.tempMinPrice.replace(/,/g, '')) : null;
            const maxPriceValue = this.tempMaxPrice ? Number(this.tempMaxPrice.replace(/,/g, '')) : null;

            if (minPriceValue && maxPriceValue) {
                if (maxPriceValue < minPriceValue) {
                    return `เริ่มต้น ${this.formatPriceShort(minPriceValue)} ฿`;
                } else {
                    return `ตั้งแต่ ${this.formatPriceShort(minPriceValue)} - ${this.formatPriceShort(maxPriceValue)} ฿`;
                }
            } else if (minPriceValue) {
                return `เริ่มต้น ${this.formatPriceShort(minPriceValue)} ฿`;
            } else if (maxPriceValue) {
                return `ไม่เกิน ${this.formatPriceShort(maxPriceValue)} ฿`;
            } else {
                return 'ช่วงราคา';
            }
        },
        // คำนวณรายการที่ต้องแสดงในหน้านั้น ๆ ตาม currentPage
        paginatedItems() {
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;
            return this.filteredItems.slice(startIndex, endIndex);
        },
        totalPages() {
            return Math.ceil(this.filteredItems.length / this.itemsPerPage);
        }
    },
    methods: {
        applyFiltersFromQuery() {
            const query = this.$route.query;
    
            if (query.category && query.category !== '') {
                this.searchFilters.Category = query.category;
            }
            if (query.adType && query.adType !== '') {
                this.searchFilters.PropertyType = query.adType;
            }
            if (query.minPrice && query.minPrice !== '') {
                this.searchFilters.MinPrice = query.minPrice;
            }
            if (query.maxPrice && query.maxPrice !== '') {
                this.searchFilters.MaxPrice = query.maxPrice;
            }
            if (query.region && query.region !== '') {
                this.searchFilters.Region = query.region;
            }
            if (query.province && query.province !== '') {
                this.searchFilters.Province = query.province;
            }
            if (query.amphur && query.amphur !== '') {
                this.searchFilters.Amphur = query.amphur;
            }
            if (query.tambon && query.tambon !== '') {
                this.searchFilters.Tambon = query.tambon;
            }

            this.fetchProperties();
        },
        async updateSeeForCurrentPage() {
            const currentIds = this.paginatedItems.map(item => item._id); // ดึง ID ในหน้าปัจจุบัน
            try {
                await axios.post('/api/property/update-see', { propertyIds: currentIds });
            } catch (error) {
                console.error('Error updating see count:', error);
            }
        },
        async fetchProperties() {
            if (this.isFetching) return; // ถ้า flag กำลังทำงานอยู่ ให้หยุด
        
            this.isFetching = true; // ตั้งค่า flag ว่า API กำลังถูกเรียกใช้งาน

            // สร้าง object ใหม่ที่มีเฉพาะค่าที่ไม่เป็นสตริงว่าง
            let params = {};
            for (let key in this.searchFilters) {
                if (this.searchFilters[key] !== '') {
                    params[key] = this.searchFilters[key];
                }
            }

            try {
                const response = await axios.get('/api/property/list', {
                    params: params,
                });
                this.items = response.data.map(property => ({
                    ...property,
                    images: property.images || [],
                    Price: property.Price.toLocaleString()
                }));
                this.filteredItems = this.items;
                this.currentPage = 1; // Reset currentPage เมื่อมีการโหลดข้อมูลใหม่
            } catch (error) {
                console.error('Error fetching properties:', error);
            } finally {
                this.isFetching = false; // รีเซ็ต flag เมื่อทำงานเสร็จ
                this.updateSeeForCurrentPage(); // เพิ่ม see สำหรับรายการที่โหลดครั้งแรก
            }
        },
        filterByLocation(region, province) {
            this.searchFilters.Region = region;
            this.searchFilters.Province = province;

            // อัปเดตค่าที่จะส่งไปยังคอมโพเนนต์ลูก
            if (region === 'ภาคกลาง' && province === 'กรุงเทพมหานคร') {
                this.toChildcomponent.Region = this.HotLocationProvince.bkk;
                this.toChildcomponent.Province = this.HotLocationRegion.bkk;
            } else if (region === 'ภาคเหนือ' && province === 'เชียงใหม่') {
                this.toChildcomponent.Region = this.HotLocationProvince.cnx;
                this.toChildcomponent.Province = this.HotLocationRegion.cnx;
            } else if (region === 'ภาคตะวันออก' && province === 'ชลบุรี') {
                this.toChildcomponent.Region = this.HotLocationProvince.chn;
                this.toChildcomponent.Province = this.HotLocationRegion.chn;
            } else if (region === 'ภาคตะวันออกเฉียงเหนือ' && province === 'ขอนแก่น') {
                this.toChildcomponent.Region = this.HotLocationProvince.kkn;
                this.toChildcomponent.Province = this.HotLocationRegion.kkn;
            } else if (region === 'ภาคกลาง' && province === 'สมุทรปราการ') {
                this.toChildcomponent.Region = this.HotLocationProvince.smk;
                this.toChildcomponent.Province = this.HotLocationRegion.smk;
            } else if (region === 'ภาคกลาง' && province === 'นนทบุรี') {
                this.toChildcomponent.Region = this.HotLocationProvince.ntb;
                this.toChildcomponent.Province = this.HotLocationRegion.ntb;
            }

            // ส่งคำสั่งให้ component ลูกรีเซ็ตข้อมูล
            if (this.$refs.regionProvinceSelector) {
                this.$refs.regionProvinceSelector.resetLocation();
                this.$refs.regionProvinceSelector.selectRegion(this.toChildcomponent.Region);
                this.$refs.regionProvinceSelector.selectProvince(this.toChildcomponent.Province);
            }
            this.scrollToTop();
            // เรียกใช้ fetchProperties เพื่ออัปเดตรายการอสังหา
            this.fetchProperties();
        },
        clearFilters() {
            // รีเซ็ตค่าของตัวแปร searchFilters ทั้งหมด
            this.searchFilters = {
                Category: '',
                PropertyType: '',
                Region: '',
                Province: '',
                Amphur: '',
                Tambon: '',
                MinPrice: '',
                MaxPrice: '',
            };
            // รีเซ็ตค่า tempMinPrice และ tempMaxPrice
            this.tempMinPrice = '';
            this.tempMaxPrice = '';

            // ส่งคำสั่งให้ component ลูกรีเซ็ตข้อมูล
            if (this.$refs.regionProvinceSelector) {
                this.$refs.regionProvinceSelector.resetLocation();
            }

            // รีเซ็ตการค้นหาด้วย fetchProperties() เพื่อดึงข้อมูลใหม่ทั้งหมด
            this.fetchProperties();
        },
        // Function to handle pagination button click
        goToPage(pageNumber) {
            if (pageNumber >= 1 && pageNumber <= this.totalPages) {
                this.currentPage = pageNumber;
                this.updateSeeForCurrentPage(); // อัปเดต see เมื่อเปลี่ยนหน้า
                this.scrollToTop();
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
                this.updateSeeForCurrentPage(); // อัปเดต see เมื่อเปลี่ยนหน้า
                this.scrollToTop();
            }
        },
        previousPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
                this.updateSeeForCurrentPage(); // อัปเดต see เมื่อเปลี่ยนหน้า
                this.scrollToTop();
            }
        },
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth' // เลื่อนขึ้นอย่างนุ่มนวล
            });
        },
        formatPriceShort(value) {
            if (value >= 1000000) {
                return `${(value / 1000000).toFixed(1).replace('.0', '')}M`;
            } else if (value >= 100000) {
                return `${(value / 1000).toFixed(1).replace('.0', '')}K`;
            } else {
                return value.toLocaleString();
            }
        },
        formatMinPrice(event) {
            let value = event.target.value.replace(/,/g, ''); // ลบเครื่องหมาย , ออกก่อน
            if (!isNaN(value) && value !== '') {
                // แปลงให้เป็นคั่นหลักแล้วอัปเดต tempMinPrice
                this.tempMinPrice = Number(value).toLocaleString();
            } else {
                this.tempMinPrice = ''; // ถ้าค่าไม่ใช่ตัวเลข, ให้เป็นค่าว่าง
            }
        },
        formatMaxPrice(event) {
            let value = event.target.value.replace(/,/g, ''); // ลบเครื่องหมาย , ออกก่อน
            if (!isNaN(value) && value !== '') {
                // แปลงให้เป็นคั่นหลักแล้วอัปเดต tempMaxPrice
                this.tempMaxPrice = Number(value).toLocaleString();
            } else {
                this.tempMaxPrice = ''; // ถ้าค่าไม่ใช่ตัวเลข, ให้เป็นค่าว่าง
            }
        },
        confirmPriceRange() {
            // แปลงค่าที่มี , ออกก่อนที่จะนำมาใช้ใน filters จริง
            const minPriceValue = this.tempMinPrice ? parseFloat(this.tempMinPrice.replace(/,/g, '')) : null;
            const maxPriceValue = this.tempMaxPrice ? parseFloat(this.tempMaxPrice.replace(/,/g, '')) : null;

            if (maxPriceValue !== null && minPriceValue !== null && maxPriceValue < minPriceValue) {
                // ถ้า maxPrice น้อยกว่า minPrice, ไม่ใช้ maxPrice
                this.searchFilters.MinPrice = minPriceValue.toString();
                this.searchFilters.MaxPrice = '';
            } else {
                this.searchFilters.MinPrice = minPriceValue ? minPriceValue.toString() : '';
                this.searchFilters.MaxPrice = maxPriceValue ? maxPriceValue.toString() : '';
            }

            this.fetchProperties(); // เรียกใช้การค้นหาข้อมูล
            this.togglePriceDropdown(); // ปิด dropdown
        },
        cancelPriceRange() {
            // รีเซ็ตค่า
            this.tempMinPrice = '';
            this.tempMaxPrice = '';
            this.searchFilters.MinPrice = '';
            this.searchFilters.MaxPrice = '';
            this.fetchProperties(); // เรียกใช้การค้นหาข้อมูล
            this.togglePriceDropdown(); // ปิด dropdown
        },
        togglePriceDropdown() {
            this.dropdownVisiblePrice = !this.dropdownVisiblePrice;
        },
        updateMinPrice(event) {
            this.minPrice = event.target.value.replace(/,/g, '');
        },
        updateMaxPrice(event) {
            this.maxPrice = event.target.value.replace(/,/g, '');
        },
        checkPropertyTypeFromRoute() {
            const propertyType = this.$route.query.propertyType;
            if (propertyType) {
                this.searchFilters.PropertyType = propertyType;
            }
        },
        navigateToDetail(itemId) {
            this.$router.push({ name: 'PropertyDetail', params: { id: itemId } });
        },
        updateFilters() {
            if (this.initialized) {
                this.fetchProperties();
            }
        },
        updateLocation(locationData) {
            this.searchFilters.Region = locationData.region;
            this.searchFilters.Province = locationData.province;
            this.searchFilters.Amphur = locationData.amphur;
            this.searchFilters.Tambon = locationData.tambon;
            this.fetchProperties();
        },
        shortenTitle(title) {
            return title.length > 40 ? title.substring(0, 40) + '...' : title;
        },
        addFocusStyle(refName) {
            const clearIcon = this.$refs[refName];
            if (clearIcon) {
                clearIcon.classList.add('focus-style');
            }
        },
        removeFocusStyle(refName) {
            const clearIcon = this.$refs[refName];
            if (clearIcon) {
                clearIcon.classList.remove('focus-style');
            }
        },
        clearMinPrice() {
            this.tempMinPrice = ''; // ล้างค่าของ tempMinPrice
        },
        clearMaxPrice() {
            this.tempMaxPrice = ''; // ล้างค่าของ tempMaxPrice
        },
    },
    // จับตาดูค่า PropertyType ที่ส่งมาจาก route หากมีการเปลี่ยนแปลงจะอัพเดทค่าและเรียกใช้เมธอด
    watch: {
        '$route.query.propertyType'(newType) {
            if (newType) {
                this.searchFilters.PropertyType = newType;
                this.fetchProperties(); // เรียก fetchProperties อีกครั้งเพื่อดึงข้อมูลใหม่ตามตัวกรอง
            }
        },
        '$route.query': {
            handler() {
                this.applyFiltersFromQuery();
                this.fetchProperties();
            },
            immediate: true, // ให้ทำงานทันทีเมื่อโหลดหน้า
        },
    },
  };
  </script>
  
  <style scoped>   
  button.custom-outline-btn {
    color: #2d1f65 !important; /* เปลี่ยนสีข้อความ */
    border-color: #2d1f65 !important; /* เปลี่ยนสีขอบ */
}

button.custom-outline-btn:hover {
    background-color: #2d1f65 !important; /* เปลี่ยนสีพื้นหลังเมื่อ hover */
    color: white !important; /* เปลี่ยนสีข้อความเมื่อ hover */
} 
/* ทำให้ทุก card มีความสูงเท่ากัน */
.item-list {
    display: flex;
    flex-wrap: wrap;
}
.property-item-custom {
    display: flex;
    flex-direction: column;
}
.card-property {
    height: 100%; /* ให้ card มีความสูงเท่ากันภายในคอลัมน์ */
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.card-body-property {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 15px;
}
.price-text {
    margin-top: auto; /* ดันราคาลงมาที่ด้านล่างสุด */
    font-weight: bold; /* เน้นให้ราคาดูเด่น */
}
  .input-group .form-control {
  flex: 1;
  border: 1px solid #ced4da;
  border-right: none; /* ลบเส้นขอบด้านขวาของ input เพื่อรวมกับกล่องกากบาท */
}
input::placeholder {
  color: lightgray; /* หรือคุณสามารถใช้ #f8f9fa หรือโทนสีอื่น ๆ ตามที่ต้องการ */
  opacity: 1; /* ปรับให้ placeholder ไม่จางเกินไป */
}
.form-control:focus {
  outline: none; /* ลบเส้นขอบ */
  box-shadow: none; /* ลบเงา */
  border-color: #6200EA; /* สีม่วงสำหรับเส้นขอบ */
}
.clear-icon {
  border: 1px solid #ced4da; /* ตั้งค่าสีขอบเริ่มต้นให้เหมือนกับ input */
  border-radius: 0.25rem; /* ปรับมุมโค้งเล็กน้อย */
  transition: border-color 0.3s ease; /* เพิ่ม transition เพื่อให้ดูสมูท */
  font-size: 1rem; /* ปรับขนาดของไอคอน */
  color: gray; /* เปลี่ยนสีของไอคอน */
  background-color: white; /* สีพื้นหลัง */
  border-left: none; /* ลบเส้นขอบด้านซ้าย */
  padding: 0.25rem; /* ปรับ padding ให้พอดี */

  display: flex; /* ปรับเพื่อให้แนวเดียวกับ input */
  align-items: center; /* จัดให้ไอคอนอยู่ตรงกลาง */
}

.focus-style {
  border-top-color: #6200EA; /* ขอบบนสีม่วง */
  border-right-color: #6200EA; /* ขอบขวาสีม่วง */
  border-bottom-color: #6200EA; /* ขอบล่างสีม่วง */
}
.dropdown-menu {
    width: 100%;
    opacity: 0;
    transform: scaleY(0);
    transform-origin: top;
    transition: opacity 0.3s ease, transform 0.3s ease;
}
.dropdown-menu.show {
    margin-top: 0.75rem;
    opacity: 1;
    transform: scaleY(1);
}
.animated-dropdown {
  display: block; /* Override Bootstrap's default hidden state */
}

.dropdown-item {
  cursor: pointer;
}
.btn-light:hover {
    background-color: #eee3fd; /* สีม่วงอ่อน */
    color: rgba(0, 0, 0, 0.85); /* รักษาสีข้อความให้คงเดิม */
    border-color: #E6E0FF; /* หากต้องการให้เส้นขอบเปลี่ยนสีตาม */
}

input.form-control {
  width: 100%;
}

.text-thin {
  font-weight: 300; /* ใช้ค่าที่ต่ำกว่า 400 เพื่อให้ตัวอักษรบางลง */
  font-size: 1rem; /* ปรับขนาดฟอนต์ได้ตามต้องการ */
}

.input-group .form-control {
  flex: 1;
  border: 1px solid #ced4da;
  border-right: none; /* ลบเส้นขอบด้านขวาของ input เพื่อรวมกับกล่องกากบาท */
}
input::placeholder {
  color: lightgray; /* หรือคุณสามารถใช้ #f8f9fa หรือโทนสีอื่น ๆ ตามที่ต้องการ */
  opacity: 1; /* ปรับให้ placeholder ไม่จางเกินไป */
}
.form-control:focus {
  outline: none; /* ลบเส้นขอบ */
  box-shadow: none; /* ลบเงา */
  border-color: #6200EA; /* สีม่วงสำหรับเส้นขอบ */
}
a {
    text-decoration: none;
}
.card-body p {
    font-size: 12px; /* หรือขนาดที่คุณต้องการ */
}
.row {
    --bs-gutter-x: 0.75rem; /* ลดค่าช่องว่างระหว่างคอลัมน์ */
}
  .dashboard-container {
    padding: 0;
  }
  
  .sidebar {
    min-height: 100vh;
    /* Ensure the sidebar takes up the full height of the screen */
  }
  
  .main-content {
    padding: 10px 0 10px 10px;
  }

  .responsive-image {
    width: 100%;
    height: 200px; /* กำหนดความสูงที่แน่นอน */
    object-fit: cover; /* ปรับให้ภาพครอบคลุมโดยไม่บิดเบี้ยว */
}
 #carditems {
  overflow: hidden; /* ซ่อนส่วนที่เกินออกมาของ card */
  transition: .5s;
}
 #carditems:hover {
    transform: scale(1.04); /* ปรับขนาดเมื่อ hover */
    cursor: pointer; /* เปลี่ยนเคอร์เซอร์เป็นมือ */
}
.zoom {
    width: 100%;
    height: 200px;
    object-fit: cover;
    transition: transform 0.5s;
}
.property-item-custom:hover .zoom {
  transform: scale(1.1);
}
.content-wrapper {
    position: relative;
    overflow: hidden;
}
.property-item img {
    transition: .5s;
}

.property-item:hover img {
    transform: scale(1.1);
}
.property-item-custom .border-top {
    border-top: 1px dashed rgba(71, 0, 185, 0.651) !important;
}

.property-item-custom .border-end {
    border-right: 1px dashed rgba(71, 0, 185, 0.651) !important;
}
.pagination-controls {
    margin-top: 20px;
}

.pagination-controls button {
    min-width: 100px;
}
.page-numbers button {
    min-width: 40px;  /* กำหนดความกว้างของปุ่มตัวเลข */
}

.page-numbers button.btn-primary {
    background-color: #310bd8;
    border-color: #310bd8;
    color: #fff;
}

.page-numbers button.btn-outline-primary {
    border-color: #310bd8;
    color: #310bd8;
}

.page-numbers button:hover {
    background-color: #eee3fd;
    color: #310bd8;
}
  @media (max-width: 576px) {
        .email-text span {
            font-size: 13px; /* Adjust the font size as needed */
        }
        .main-content {
            padding: 10px 0 10px 0px;
        }
    }
  </style>
  