<template>
    <div class="d-flex align-items-center justify-content-center bg-light p-1" style="min-height: 50vh;">
      <div class="card shadow-lg w-100" style="max-width: 400px;">
        <div class="card-body">
          <!-- ส่วนหัว -->
          <div class="text-center mb-3">
            <h2 class="h4 font-weight-bold text-dark">ลืมรหัสผ่าน</h2>
            <p class="text-muted small mb-4">
              กรุณากรอกอีเมลที่ใช้ในการลงทะเบียน
            </p>
          </div>
  
          <!-- ฟอร์ม -->
          <form @submit.prevent="handleSubmit">
            <!-- อีเมล -->
            <div class="form-floating mb-3">
              <input
                type="email"
                id="email"
                v-model="email"
                class="form-control"
                placeholder="อีเมล"
                required
              />
              <label for="email">อีเมล</label>
            </div>
  
            <!-- ปุ่มส่ง -->
            <div class="d-grid">
              <button
                type="submit"
                :disabled="isLoading"
                class="btn btn-primary"
              >
                <span v-if="isLoading">กำลังดำเนินการ...</span>
                <span v-else>ส่งลิงก์รีเซ็ตรหัสผ่าน</span>
              </button>
            </div>
          </form>
  
          <!-- ข้อความแจ้งเตือน -->
          <div
            v-if="message"
            class="mt-3 text-center"
            :class="[
              message.type === 'success' ? 'text-success' : 'text-danger',
              'small'
            ]"
          >
            {{ message.text }}
          </div>
  
          <!-- ลิงก์กลับ -->
          <div class="text-center mt-3">
            <router-link to="/login" class="text-decoration-none small text-primary">
              กลับไปหน้าเข้าสู่ระบบ
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "ForgotPassword",
    data() {
      return {
        email: "",
        isLoading: false,
        message: null,
      };
    },
    methods: {
      async handleSubmit() {
        try {
          this.isLoading = true;
          this.message = null;
  
          const response = await fetch("/api/mail/forgot-password", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ email: this.email }),
          });
  
          const data = await response.json();
  
          if (data.success) {
            this.message = {
              type: "success",
              text: data.message,
            };
            this.email = "";
          } else {
            throw new Error(data.message);
          }
        } catch (error) {
          this.message = {
            type: "error",
            text: error.message || "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง",
          };
        } finally {
          this.isLoading = false;
        }
      },
    },
  };
  </script>
  
  <style scoped>
  /* ลดพื้นที่ว่างรอบ card */
  body {
    background-color: #f8f9fa;
    margin: 0;
    padding: 0;
  }
  
  .card {
    border-radius: 12px;
  }
  
  .btn-primary {
    background-color: #0d6efd;
    border: none;
  }
  
  .btn-primary:hover {
    background-color: #084298;
  }
  
  .text-muted {
    font-size: 0.875rem;
  }
  </style>
  