<template>
  <div class="container bg-white rounded shadow p-3" style="border: 1px solid #2d1f65;">
    <section class="sect">
      <div class="mt-5">
        <h3>กรอกข้อมูลอสังหาริมทรัพย์</h3>
      </div>

      <div class="container px-md-5 my-5"> <!-- เพิ่ม px-md-5 เพื่อให้มี padding สำหรับหน้าจอขนาด medium ขึ้นไป -->
        <form @submit.prevent="handleSubmit">
          <!-- ประเภทการขาย -->
          <div class="mb-3 row">
            <label class="col-sm-3 col-form-label text-start">ประเภทการขาย <span class="text-danger">*</span></label>
            <div class="col-sm-9 text-start">
              <div class="form-check form-check-inline">
                <input v-model="PropertyType" class="form-check-input" type="radio" id="sale" value="ขาย" require>
                <label class="form-check-label" for="sale">ขาย</label>
              </div>
              <div class="form-check form-check-inline">
                <input v-model="PropertyType" class="form-check-input" type="radio" id="rent" value="เช่า">
                <label class="form-check-label" for="rent">ให้เช่า</label>
              </div>
            </div>
          </div>
          <!-- <p>Selected Type: {{ PropertyType }}</p> -->
           <!-- หมวดหมู่ -->
          <div class="mb-3 row">
            <label for="category" class="col-sm-3 col-form-label text-start">หมวดหมู่ <span class="text-danger">*</span></label>
            <div class="col-sm-9 col-md-2">
              <div class="dropdown">
                <a class="btn btn-secondary form-control dropdown-toggle bg-light text-dark text-start" role="button" data-bs-toggle="dropdown" aria-expanded="true">{{ displayCategory }}</a>
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" @click="selectCategory('ที่ดิน')">ที่ดิน</a></li>
                  <li><a class="dropdown-item" @click="selectCategory('บ้าน')">บ้าน</a></li>
                  <li><a class="dropdown-item" @click="selectCategory('คอนโด')">คอนโด</a></li>
                  <li><a class="dropdown-item" @click="selectCategory('ทาวน์โฮม')">ทาวน์โฮม</a></li>
                  <li><a class="dropdown-item" @click="selectCategory('อาคารพาณิชย์')">อาคารพาณิชย์</a></li>
                  <li><a class="dropdown-item" @click="selectCategory('อื่น ๆ')">อื่น ๆ</a></li>
                </ul>
              </div>
            </div>
            <!-- ช่องกรอกเพิ่มเติมเมื่อเลือก 'อื่น ๆ' -->
            <div class="col-sm-9 col-md-3 mt-2 mt-md-0" v-if="isOtherSelected">
              <input v-model="otherCategory" type="text" class="form-control" placeholder="โปรดระบุ" required/>
            </div>
          </div>

          <LocateSelector
            v-model:locationData="locationData"
          />

          <!-- หัวข้ออสังหาริมทรัพย์ -->
          <div class="mb-3 row">
            <label for="productName" class="col-sm-3 col-form-label text-start">หัวข้ออสังหาริมทรัพย์ <span class="text-danger">*</span></label>
            <div class="col-sm-9">
              <input v-model="Title" type="text" class="form-control" id="productName" placeholder="หัวข้อประกาศ เช่น ขายบ้านทำเลดีย่านการค้า" required>
            </div>
          </div>
          <!-- ฟิลด์รายละเอียดบ้าน (แสดงเมื่อเลือกหมวดหมู่ที่ไม่ใช่ที่ดิน) -->
          <div v-if="Category !== 'ที่ดิน'" class="mb-3 row">
            <label class="col-sm-3 col-form-label text-start">รายละเอียดบ้าน <span class="text-danger">*</span></label>
            <div class="col-sm-9">
              <div class="row mb-3">
                <div class="col-md-4">
                  <input v-model="houseDetails.bedrooms"  @input="validateInteger('bedrooms', $event)" min="0" step="1" type="number" class="form-control my-1 my-md-0" placeholder="จำนวนห้องนอน">
                </div>
                <div class="col-md-4">
                  <input v-model="houseDetails.bathrooms"  @input="validateInteger('bathrooms', $event)" min="0" step="1" type="number" class="form-control my-1 my-md-0" placeholder="จำนวนห้องน้ำ">
                </div>
                <div class="col-md-4">
                  <input v-model="houseDetails.floors" @input="validateInteger('floors', $event)" min="0" step="1" type="number" class="form-control my-1 my-md-0" placeholder="จำนวนชั้น">
                </div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <input v-model="houseDetails.landScale"  @input="validateInteger('landScale', $event)" min="1" step="1" type="number" class="form-control my-1 my-md-0" placeholder="ขนาดที่ดิน (ตร.ว)">
                </div>
                <div class="col-md-4">
                  <input v-model="houseDetails.houseScale"  @input="validateInteger('houseScale', $event)" min="0" step="1" type="number" class="form-control my-1 my-md-0" placeholder="พื้นที่ใช้สอย (ตร.ม)">
                </div>
                <div class="col-md-4">
                  <input v-model="houseDetails.carsSlot" @input="validateInteger('carsSlot', $event)" min="0" step="1" type="number" class="form-control my-1 my-md-0" placeholder="จำนวนพื้นที่จอดรถ">
                </div>
              </div>
            </div>
          </div>
          <!-- สิ่งอำนวยความสะดวกในบ้าน -->
          <div v-if="Category !== 'ที่ดิน'" class="mb-3 row">
            <label class="col-sm-3 col-form-label text-start">สิ่งอำนวยความสะดวกในบ้าน</label>
            <div class="col-sm-9">
              <div class="row">
                <!-- Column 1 -->
                <div class="col-md-6">
                  <div class="col-md-10 mx-auto">
                    <div class="form-check my-4">
                      <input v-model="facilities.furniture" class="form-check-input" type="checkbox" id="furniture">
                      <label class="form-check-label" for="furniture">เฟอร์นิเจอร์</label>
                    </div>
                    <div class="form-check my-4">
                      <input v-model="facilities.airConditioner" class="form-check-input" type="checkbox" id="airConditioner">
                      <label class="form-check-label" for="airConditioner">เครื่องปรับอากาศ</label>
                    </div>
                    <div class="form-check my-4">
                      <input v-model="facilities.digitalLock" class="form-check-input" type="checkbox" id="digitalLock">
                      <label class="form-check-label" for="digitalLock">ประตูดิจิตอล</label>
                    </div>
                    <div class="form-check my-4">
                      <input v-model="facilities.tv" class="form-check-input" type="checkbox" id="tv">
                      <label class="form-check-label" for="tv">โทรทัศน์</label>
                    </div>
                    <div class="form-check my-4">
                      <input v-model="facilities.refrigerator" class="form-check-input" type="checkbox" id="refrigerator">
                      <label class="form-check-label" for="refrigerator">ตู้เย็น</label>
                    </div>
                    <div class="form-check my-4">
                      <input v-model="facilities.microwave" class="form-check-input" type="checkbox" id="microwave">
                      <label class="form-check-label" for="microwave">ไมโครเวฟ</label>
                    </div>
                    <div class="form-check my-4">
                      <input v-model="facilities.swimmingpool" class="form-check-input" type="checkbox" id="swimmingpool">
                      <label class="form-check-label" for="swimmingpool">สระว่ายน้ำ</label>
                    </div>
                  </div>
                </div>

                <!-- Column 2 -->
                <div class="col-md-6">
                  <div class="col-md-10 mx-auto">
                    <div class="form-check my-4">
                      <input v-model="facilities.landlinePhone" class="form-check-input" type="checkbox" id="landlinePhone">
                      <label class="form-check-label" for="landlinePhone">โทรศัพท์บ้าน</label>
                    </div>
                    <div class="form-check my-4">
                      <input v-model="facilities.hotWaterMachine" class="form-check-input" type="checkbox" id="hotWaterMachine">
                      <label class="form-check-label" for="hotWaterMachine">เครื่องทำน้ำอุ่น</label>
                    </div>
                    <div class="form-check my-4">
                      <input v-model="facilities.bathtub" class="form-check-input" type="checkbox" id="bathtub">
                      <label class="form-check-label" for="bathtub">อ่างอาบน้ำ</label>
                    </div>
                    <div class="form-check my-4">
                      <input v-model="facilities.kitchen" class="form-check-input" type="checkbox" id="kitchen">
                      <label class="form-check-label" for="kitchen">เตาปรุงอาหาร</label>
                    </div>
                    <div class="form-check my-4">
                      <input v-model="facilities.cookerhood" class="form-check-input" type="checkbox" id="cookerhood">
                      <label class="form-check-label" for="cookerhood">เครื่องดูดควัน</label>
                    </div>
                    <div class="form-check my-4">
                      <input v-model="facilities.fitness" class="form-check-input" type="checkbox" id="fitness">
                      <label class="form-check-label" for="fitness">ฟิตเนส</label>
                    </div>
                    <div class="form-check my-4">
                      <input v-model="facilities.fan" class="form-check-input" type="checkbox" id="fan">
                      <label class="form-check-label" for="fan">พัดลม</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- รายละเอียด -->
          <div class="mb-3 row">
            <label for="detail" class="col-sm-3 col-form-label text-start">รายละเอียด <span class="text-danger">*</span></label>
            <div class="col-sm-9">
              <textarea v-model="Detail" id="detail" name="detail" cols="40" rows="10" class="form-control" placeholder="ระบุรายละเอียดของอสังหาริมทรัพย์ เช่น ลักษณะบ้าน ลักษณะที่ดิน" required></textarea>
            </div>
          </div>

          <!-- เนื้อที่ -->
          <div class="mb-3 row">
            <label for="scale" class="col-sm-3 col-form-label text-start">เนื้อที่ <span class="text-danger">*</span></label>
            <div class="col-sm-9 col-md-9">
              <div class="row">
                <!-- ไร่ -->
                <div class="col-12 col-md">
                  <div class="input-group mb-2 mb-md-0">
                    <input v-model="Scale.ScaleRai" @input="validateInteger('ScaleRai', $event)" type="number" min="0" step="1" class="form-control text-center" placeholder="0" aria-label="Rai" required>
                    <span class="input-group-text">ไร่</span>
                  </div>
                </div>

                <!-- งาน -->
                <div class="col-12 col-md">
                  <div class="input-group mb-2 mb-md-0">
                    <input v-model="Scale.ScaleNgan" @input="validateInteger('ScaleNgan', $event)" type="number" min="0" step="1" class="form-control text-center" placeholder="0" aria-label="Ngan" required>
                    <span class="input-group-text">งาน</span>
                  </div>
                </div>

                <!-- ตารางวา -->
                <div class="col-12 col-md">
                  <div class="input-group mb-2 mb-md-0">
                    <input v-model="Scale.ScaleWah" @input="validateInteger('ScaleWah', $event)" type="number" min="0" step="1" class="form-control text-center" placeholder="0" aria-label="Square Wah" required>
                    <span class="input-group-text">ตารางวา</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- ราคา -->
          <div class="mb-3 row">
            <label for="price" class="col-sm-3 col-form-label text-start">ราคา <span class="text-danger">*</span></label>
            <div class="col-sm-9 col-md-4">
              <div class="input-group">
                <input v-model="formattedPrice" @input="updatePrice" type="text" class="form-control" id="price" placeholder="ตัวเลขไม่ต้องใส่ ," required>
                <span class="input-group-text">บาท</span>
              </div>
            </div>
          </div>

          <!-- ทำเล -->
          <div class="mb-3 row">
            <label for="position" class="col-sm-3 col-form-label text-start">ทำเล</label>
            <div class="col-sm-9">
              <input v-model="Position" type="text" class="form-control" id="position" placeholder="เช่น ใกล้ตลาด, ใกล้โรงเรียน">
            </div>
          </div>

          <!-- การติดต่อ -->
          <div class="mb-3 row">
            <label for="Telnum" class="col-sm-3 col-form-label text-start">การติดต่อ <span class="text-danger">*</span></label>
            <div class="col-sm-9 col-md-4">
              <input v-model="telValue" :disabled="isTelDisabled" type="tel" class="form-control" id="Telnum" placeholder="เบอร์โทรศัพท์" required>
            </div>
          </div>
          <!-- Line id -->
          <div class="mb-3 row">
            <label for="line" class="col-sm-3 col-form-label text-start">Line ID</label>
            <div class="col-sm-9 col-md-4">
              <input v-model="lineValue" :disabled="isLineDisabled" type="text" class="form-control" id="line" placeholder="ไอดีไลน์">
            </div>
          </div>
          <!-- Google Maps Link -->
          <div class="mb-3 row">
            <label for="googleMap" class="col-sm-3 col-form-label text-start">แผนที่</label>
            <div class="col-sm-4 col-md-4">
              <input v-model="Latitude" type="text" class="form-control my-1 my-md-0" id="latitude" placeholder="ละติจูด">
            </div>
            <div class="col-sm-4 col-md-4">
              <input v-model="Longitude" type="text" class="form-control my-1 my-md-0" id="googleMap" placeholder="ลองจิจูด">
            </div>
          </div>
          <!-- เลือกรูปภาพ -->
          <div class="mb-5 row">
            <label for="imageInput" class="col-sm-3 col-form-label text-start">เลือกรูปภาพ <span class="text-danger">*</span></label>
            <div class="col-sm-9 col-md-4">
              <input type="file" ref="imageInput" class="form-control" id="imageInput" required @change="handleImageChange" multiple/>
            </div>
          </div>
          <!-- เพิ่มส่วนแสดงตัวอย่างรูปภาพ -->
          <div class="row mb-5 ">
            <div class="col-sm-9 offset-sm-3 bg-white border rounded">
              <div class="row">
                <div class="col-3 mb-3" v-for="(image, index) in imagePreviews" :key="index">
                  <div class="card position-relative mt-2">
                    <img :src="image" class="card-img" alt="image preview" />
                    <!-- ปุ่มลบรูปภาพ -->
                    <button type="button" class="btn btn-danger btn-sm position-absolute top-0 end-0 m-2" @click="removeImage(index)">
                      <i class="bi bi-trash"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-5 row">
            <div class="col-12 col-sm-12 col-md-12" @click="selectCard(this.ExCard.value)">
              <div class="card-container bg-white rounded shadow p-1 m-1" style="border: 2px solid #efbf04;" :class="{ 'selected-card': selectedCard === this.ExCard.value }">
                <div class="exclusive-badge-right mb-md-4 mb-2"><i class="fa-brands fa-square-web-awesome-stroke me-2 fa-xl"></i>{{ ExCard.label }}</div>
                <p><i class="fa fa-check me-md-3 me-1" style="color: #efbf04;"></i>{{ ExCard.description1 }}</p>
                <p><i class="fa fa-check me-md-3 me-1" style="color: #efbf04;"></i>{{ ExCard.description2 }}</p>
                <p><i class="fa fa-check me-md-3 me-1" style="color: #efbf04;"></i>{{ ExCard.description3 }}</p>
                <p><i class="fa fa-check me-md-3 me-1" style="color: #efbf04;"></i>{{ ExCard.description4 }}</p>
                <p><i class="fa fa-check me-md-3 me-1" style="color: #efbf04;"></i>{{ ExCard.description5 }}</p>
                <p><i class="fa fa-check me-md-3 me-1" style="color: #efbf04;"></i>{{ ExCard.description6 }}</p>
                <h3 class="py-2" style="color: #efbf04; font-weight: 600;"><b>{{ this.exclusiveCredit }} Credit</b></h3>
                <h6 class="pt-2" style="color: green; font-size: 12px">อายุ 30 วัน</h6>
                <h6 class="pt-2" style="color: green;">ใช้ได้ {{ $store.state.Postextra.exclusive }} (ไม่เสียเครดิต)</h6>
              </div>
            </div>
          </div>
          <div class="mb-5 row">
            <div class="col-12 col-sm-6 col-md-4" v-for="(card, index) in cards" :key="index" @click="selectCard(card.value)">
              <div v-if="card.value === 'premium'" class="card-container bg-white rounded shadow p-1 m-1 mb-2" style="border: 2px solid #310bd8;" :class="{ 'selected-card': selectedCard === card.value }">
                <div class="exclusive-badge-right" style="background-color: #310bd8;">{{ card.label }}</div>
                <div class="p-3">
                  <span>{{ card.description }}</span>
                </div>
                <h5 class="py-2" style="color: #310bd8; font-weight: 600;"><b>{{ this.premiumCredit }} Credit</b></h5>
                <p class="pt-2" style="color: green; font-size: 12px">อายุ 30 วัน</p>
                <h6 class="pt-2" style="color: green;">ใช้ได้ {{ $store.state.Postextra.premium }} (ไม่เสียเครดิต)</h6>
              </div>
              <div v-if="card.value === 'hot'" class="card-container bg-white rounded shadow p-1 m-1 mb-2" style="border: 2px solid #c92a0d;" :class="{ 'selected-card': selectedCard === card.value }">
                <div class="exclusive-badge-right" style="background-color: #c92a0d;">{{ card.label }}</div>
                <div class="p-3">
                  <span>{{ card.description }}</span> 
                </div>
                <h5 class="py-2" style="color: #c92a0d; font-weight: 600;"><b>{{ this.hotCredit }} Credit</b></h5>
                <p class="pt-2" style="color: green; font-size: 12px;">อายุ 30 วัน</p>
                <h6 class="pt-2" style="color: green; ">ใช้ได้ {{ $store.state.Postextra.hot }} (ไม่เสียเครดิต)</h6>
              </div>
              <div v-if="card.value === 'standard'" class="card-container bg-white rounded shadow p-1 m-1 mb-2" style="border: 2px solid gray;" :class="{ 'selected-card': selectedCard === card.value }">
                <div class="exclusive-badge-right" style="background-color: gray;">{{ card.label }}</div>
                <div class="p-3">
                  <span>{{ card.description }}</span>
                </div>
                <h5 class="py-2" style="color: gray; font-weight: 600;"><b>Free Credit</b></h5>
                <p class="pt-2" style="color: green; font-size: 12px;">อายุ 365 วัน</p>
                <h6 class="pt-2" style="color: green; ">ใช้ได้ไม่จำกัด </h6>
              </div>
            </div>
          </div>
          <div class="row my-3">
            <p style="color: red; font-size: 12px">*** หมายเหตุ ***</p>
            <p style="color: red; font-size: 12px">ประกาศฟรีจะลงได้ตามจำนวนที่ Package ระบุ</p>
            <p style="color: red; font-size: 12px">ประกาศที่ระดับสูงกว่า Standard หลังหมดอายุประกาศจะถูกเปลี่ยนเป็น Standard อัตโนมัติ</p>
          </div>

          <div v-if="errorForm.length > 0" class="alert alert-danger">
            <ul class="d-flex flex-column align-items-center my-2">
              <li v-for="(error, index) in errorForm" :key="index" >{{ error }}</li>
            </ul>
          </div>

          <!-- ปุ่มเพิ่มประกาศ -->
          <div class="my-3 row">
            <div class="text-center">
              <button type="submit" class="btn btn-primary">ลงประกาศ</button>
            </div>
          </div>

          <teleport to="body">
            <!-- Backdrop -->
            <transition name="backdrop">
              <div v-if="showModal" class="modal-backdrop fade show" @click="showModal = false"></div>
            </transition>
            <!-- Modal -->
            <transition name="modal">
              <div v-if="showModal" class="modal fade show" tabindex="-1"  role="dialog" style="display: block;">
                <div class="modal-dialog modal-dialog-centered custom-modal-dialog" role="document">
                  <div class="modal-content custom-modal-content">
                    <!-- Modal Header -->
                    <div class="modal-header">
                      <div class="mx-auto">
                        <h5 class="modal-title p-2">ยืนยันการลงประกาศ</h5>
                        <button type="button" class="btn-close" @click="showModal = false" style="position: absolute; right: 20px; top: 20px;"></button>
                      </div>
                    </div>
                    <!-- Modal Body -->
                    <div class="modal-body d-flex align-items-center justify-content-center">
                      <div class="text-center">
                        <div class="p-4">
                          <h6>คุณต้องการยืนยันการบันทึกข้อมูลหรือไม่?</h6>
                        </div>
                      </div>
                    </div>
                    <!-- Modal Footer -->
                    <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" @click="showModal = false">ยกเลิก</button>
                      <button type="button" class="btn btn-primary" @click="confirmSubmit">ยืนยัน</button>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </teleport>

          <!-- Modal สำหรับแจ้งเตือนการบันทึกสำเร็จหรือไม่สำเร็จ -->
          <teleport to="body">
            <transition name="backdrop">
              <div v-if="showNotification" class="modal-backdrop fade show"></div>
            </transition>
            <transition name="modal">
              <div v-if="showNotification" class="modal fade show" tabindex="-1" role="dialog" style="display: block;">
                <div class="modal-dialog modal-dialog-centered custom-modal-dialog" role="document">
                  <div class="modal-content custom-modal-content">
                    <div class="modal-header">
                      <div class="mx-auto">
                        <div class="modal-body p-2" v-if="isSuccess">
                          <div class="mx-auto">
                            <div class="col-8 mx-auto text-center">
                              <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 131 131"
                                width="100%"
                                height="100%" 
                              >
                                <circle
                                  class="circle"
                                  fill="none"
                                  stroke="#198754"
                                  stroke-width="6"
                                  stroke-miterlimit="10"
                                  cx="65.1"
                                  cy="65.1"
                                  r="62.1"
                                />
                                <polyline
                                  class="check"
                                  fill="none"
                                  stroke="#198754"
                                  stroke-width="6"
                                  stroke-linecap="round"
                                  stroke-miterlimit="10"
                                  points="100.2,40.2 51.5,88.8 29.8,67.5 "
                                />
                              </svg>  
                            </div>
                          </div>
                          <h3 class="text-success mx-auto mt-3">เพิ่มประกาศสำเร็จ</h3> 
                        </div>
                        <div class="modal-body p-2" v-if="!isSuccess">
                          <div class="mx-auto">
                            <div class="col-8 mx-auto text-center">
                              <svg
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 131 131"
                                width="100%"
                                height="100%"
                              >
                                <circle
                                  class="circle"
                                  fill="none"
                                  stroke="#db3646"
                                  stroke-width="6"
                                  stroke-miterlimit="10"
                                  cx="65.1"
                                  cy="65.1"
                                  r="62.1"
                                />
                                <line
                                  class="line1"
                                  fill="none"
                                  stroke="#db3646"
                                  stroke-width="6"
                                  stroke-linecap="round"
                                  stroke-miterlimit="10"
                                  x1="34.4"
                                  y1="37.9"
                                  x2="95.8"
                                  y2="92.3"
                                />
                                <line
                                  class="line2"
                                  fill="none"
                                  stroke="#db3646"
                                  stroke-width="6"
                                  stroke-linecap="round"
                                  stroke-miterlimit="10"
                                  x1="95.8"
                                  y1="38"
                                  x2="34.4"
                                  y2="92.2"
                                />
                              </svg>
                            </div>
                          </div>
                          <h3 class="text-danger text-center mt-3">เพิ่มประกาศล้มเหลว</h3> 
                        </div>
                        <button type="button" class="btn-close" v-if="!isSuccess" @click="closeNotification" style="position: absolute; right: 20px; top: 20px;"></button>
                      </div>
                    </div>
                    <div class="modal-body d-flex align-items-center justify-content-center">
                      <div class="text-center">
                        <h6 v-if="isSuccess">ระบบจะรีเฟรชหน้าจอในไม่ช้า...</h6>
                        <h6 v-if="notificationMessage" class="text-danger">{{ this.notificationMessage }}</h6>
                        <div v-if="!isSuccess && !notificationMessage" class="text-danger">
                          <h6>กรุณาตรวจสอบข้อมูลหรือลองอีกครั้งในภายหลัง</h6>
                          <h6>หากพบปัญหากรุณาติดต่อแอดมิน</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </teleport>
          <teleport to="body">
            <transition name="backdrop">
              <div v-if="isSubmitting" class="modal-backdrop fade show"></div>
            </transition>
            <transition name="modal">
              <div v-if="isSubmitting" class="modal fade show" tabindex="-1" role="dialog" style="display: block;">
                <div class="modal-dialog modal-dialog-centered custom-modal-dialog" role="document">
                  <div class="modal-content custom-modal-content">
                    <div class="modal-body d-flex align-items-center justify-content-center">
                      <div class="text-center">
                        <!-- แสดงอนิเมชันโหลดจาก Bootstrap -->
                        <div class="spinner-border text-primary" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                        <!-- แสดงข้อความกำลังบันทึก -->
                        <div class="mt-3">
                          <h5>กำลังบันทึกข้อมูล...</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </teleport>
          <!-- Modal แจ้งเตือนรูปภาพ -->
          <teleport to="body">
            <transition name="backdrop">
              <div v-if="showSizeLimitModal" class="modal-backdrop fade show"></div>
            </transition>
            <transition name="modal">
              <div v-if="showSizeLimitModal" class="modal fade show" tabindex="-1" role="dialog" style="display: block;">
                <div class="modal-dialog modal-dialog-centered custom-modal-dialog" role="document">
                  <div class="modal-content custom-modal-content">
                    <div class="modal-header">
                      <div class="mx-auto">
                        <h5 class="modal-title p-2 text-danger">ข้อผิดพลาดในการอัพโหลดรูปภาพ</h5>
                        <button type="button" class="btn-close" @click="showSizeLimitModal = false" style="position: absolute; right: 20px; top: 20px;"></button>
                      </div>
                    </div>
                    <div class="modal-body d-flex align-items-center justify-content-center">
                      <div class="text-center">
                        <div class="p-4">
                          <h6 class="text-danger">ไฟล์ "{{ oversizedFileName }}"</h6>
                          <h6 class="text-danger">{{ fileErrorMessage }}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </teleport>
          
        </form>
      </div>
    </section>
  </div>
</template>

  
<script>
import axios from '@/axios.js';
import LocateSelector from './LocateSelector.vue';
import { mapGetters } from 'vuex';


export default {
  name: 'AddProperty2',
  components: {
    LocateSelector,
  },
  data() {
    return {
      Title: '',
      Category: null,
      Detail: null,
      Position: '',
      selectedCard: null,
      Telnum: '',
      Line: '',
      Latitude: '',
      Longitude:'',
      ExCard: {
        label: "Exclusive",
        value: "exclusive",
        description1: "ประกาศสุด Exclusive",
        description2: "อยู่หน้าแรกและบนสุดของเว็บ",
        description3: "อยู่ด้านบนประกาศทุกประเภทในหน้าประกาศ",
        description4: "ไม่ต้องเสียเวลาเลื่อนประกาศ",
        description5: "มองเห็นได้ชัดที่สุดบนเว็บ",
        description6: "โควต้าจำกัดลงได้แค่ 2 ประกาศบนเว็บเท่านั้น",
      },
      cards: [
        { label: "Premium", value: "premium", description: "ติดหน้าแรกและอยู่ด้านบนประกาศ HOT เสมอ" },
        { label: "Hot", value: "hot", description: "ประกาศอยู่บนประกาศ Standard เสมอ" },
        { label: "Standard", value: "standard", description: "ประกาศฟรี ไม่เสียเครดิตหรือค่าธรรมเนียม" },
      ],
      Scale: {
        ScaleRai: null,
        ScaleNgan: null,
        ScaleWah: null,
      },
      locationData: {
        region: null,
        province: null,
        amphur: null,
        tambon: null
      },
      facilities: {
        furniture: false,
        airConditioner: false,
        digitalLock: false,
        tv: false,
        refrigerator: false,
        landlinePhone: false,
        hotWaterMachine: false,
        bathtub: false,
        kitchen: false,
        cookerhood: false,
        fitness: false,
        fan: false,
        swimmingpool: false,
        microwave: false
      },
      houseDetails: {
        bedrooms: null,
        bathrooms: null,
        floors: null,
        landScale: null,
        houseScale: null,
        carsSlot: null
      },
      imagePreviews: [],
      PropertyType: null,
      isOtherSelected: false,              // เช็คว่าถ้าเลือก 'อื่น ๆ'
      otherCategory: '',                   // เก็บค่าหมวดหมู่ที่กรอกเพิ่มเติม
      FormValid: true,
      errorForm: [],
      showModal: false,
      showSizeLimitModal: false,
      oversizedFileName: '', // เก็บชื่อไฟล์ที่มีปัญหา
      fileErrorMessage: '', // เก็บข้อความแสดงข้อผิดพลาด
      Price: '',  // ตัวเลขที่ใช้ในการคำนวณจริง
      showNotification: false, // สำหรับแจ้งเตือนการบันทึกสำเร็จหรือไม่
      isSuccess: false,  // เช็คสถานะว่าบันทึกสำเร็จหรือไม่
      isSubmitting: false,
      notificationMessage: '',
      exclusiveCredit: 999,
      premiumCredit: 249,
      hotCredit: 99,
    };
  },
  mounted() {
    axios.get('/csrf-token')
    .then(response => {
      this.csrfToken = response.data.csrfToken;
    })
    .catch(error => {
      console.error('Error fetching CSRF token:', error);
    });
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'userId', 'userName', 'tel', 'line', 'name', 'credit', 'message', 'sessionExpired']),
    // ฟอร์แมตราคาให้มีคอมม่า
    formattedPrice() {
      if (!this.Price) return '';  // ถ้าไม่มีค่า ให้แสดงเป็นค่าว่าง
      return Number(this.Price).toLocaleString();  // ใส่คอมม่าในตัวเลข
    },
    displayCategory() {
      return this.Category ? this.Category : 'หมวดหมู่';
    },
    myCredit() {
      return this.$store.state.credit;
    },
    // สร้าง computed property เพื่อให้สามารถใช้งานกับฟิลด์ในฟอร์ม
    // การแสดงค่าของเบอร์โทรศัพท์และไลน์: ถ้ามีใน Vuex ให้ใช้ค่านั้น ถ้าไม่มีก็ให้ใช้ค่าที่ผู้ใช้กรอกเข้ามา
    telValue: {
      get() {
        return this.tel ? this.tel : this.Telnum;  // ถ้ามีใน Vuex ใช้ Vuex ถ้าไม่มี ใช้ Telnum ที่ผู้ใช้กรอก
      },
      set(value) {
        this.Telnum = value;  // ถ้าผู้ใช้กรอก ให้เก็บไว้ใน Telnum
      }
    },
    lineValue: {
      get() {
        return this.line ? this.line : this.Line;  // ถ้ามีใน Vuex ใช้ Vuex ถ้าไม่มี ใช้ Line ที่ผู้ใช้กรอก
      },
      set(value) {
        this.Line = value;  // ถ้าผู้ใช้กรอก ให้เก็บไว้ใน Line
      }
    },
    isTelDisabled() {
      return !!this.tel; // ถ้ามีข้อมูล tel จาก Vuex ฟิลด์นี้จะถูกปิด
    },
    isLineDisabled() {
      return !!this.line; // ถ้ามีข้อมูล line จาก Vuex ฟิลด์นี้จะถูกปิด
    }
  },
  methods: {
    // ใน methods ของคุณ
    handleImageChange(event) {
      const files = event.target.files;

      for (let file of files) {
        // ตรวจสอบประเภทของไฟล์ ถ้าไม่ใช่ภาพให้แสดง modal แจ้งเตือน
        if (!file.type.startsWith('image/')) {
          this.oversizedFileName = file.name;
          this.fileErrorMessage = 'กรุณาอัพโหลดรูปภาพเท่านั้น';
          this.showSizeLimitModal = true;  // แสดง modal แจ้งเตือนประเภทไฟล์ไม่ถูกต้อง
          this.$refs.imageInput.value = ''; // เคลียร์ค่าของ input file
          continue;
        }

        // ตรวจสอบขนาดของไฟล์ ถ้าใหญ่เกิน 2MB ให้แสดง modal แจ้งเตือน
        if (file.size > 1 * 1024 * 1024) {
          this.oversizedFileName = file.name;
          this.fileErrorMessage = 'ไฟล์มีขนาดใหญ่เกิน 1MB กรุณาเลือกไฟล์ที่มีขนาดเล็กกว่า 1MB';
          this.showSizeLimitModal = true;  // แสดง modal แจ้งเตือนขนาดไฟล์เกินกำหนด
          this.$refs.imageInput.value = ''; // เคลียร์ค่าของ input file
          continue;
        }

        const reader = new FileReader();
        reader.onload = (e) => {
          // เพิ่มรูปใหม่เข้าไปใน imagePreviews โดยไม่รีเซ็ตค่าเดิม
          this.imagePreviews.push(e.target.result);
        };
        reader.readAsDataURL(file);
      }
    },
    isFormValid() {
      this.errorForm = [];  // รีเซ็ตข้อผิดพลาดก่อนทำการตรวจสอบใหม่
      if (!this.PropertyType) {
        this.errorForm.push('กรุณาเลือกประเภทการขาย');
      }
      if (!this.Category) {
        this.errorForm.push('กรุณาเลือกหมวดหมู่');
      }
      if (this.isOtherSelected && !this.otherCategory) {
        this.errorForm.push('กรุณาระบุหมวดหมู่เพิ่มเติม');
      }
      if (!this.locationData.region || !this.locationData.province || !this.locationData.amphur || !this.locationData.tambon) {
        this.errorForm.push('กรุณากรอกข้อมูลที่อยู่ให้ครบถ้วน');
      }
      if (this.Category !== 'ที่ดิน') {
        if (!this.houseDetails.bedrooms || !this.houseDetails.bathrooms || !this.houseDetails.floors || !this.houseDetails.landScale) {
          this.errorForm.push('กรุณาระบุลักษณะบ้าน');
        }
      }
      if (!this.selectedCard) {
        this.errorForm.push('กรุณาระบุชนิดประกาศ');
      }
      // ตรวจสอบเครดิตของผู้ใช้และประเภทประกาศ
      if (this.selectedCard === 'premium' && this.myCredit < 500) {
        this.errorForm.push('เครดิตไม่เพียงพอสำหรับประกาศ Premium (ต้องใช้ 500 เครดิต)');
      } else if (this.selectedCard === 'hot' && this.myCredit < 99) {
        this.errorForm.push('เครดิตไม่เพียงพอสำหรับประกาศ Hot (ต้องใช้ 99 เครดิต)');
      }
      if(this.Latitude || this.Longitude) {
        this.validateCoordinates();
      }
      // ถ้าทุกฟิลด์ที่จำเป็นกรอกครบถ้วน return true
      return this.errorForm.length === 0;
    },
    validateCoordinates() {
      // ลบช่องว่างด้านหน้าและด้านหลัง
      this.Latitude = this.Latitude.trim();
      this.Longitude = this.Longitude.trim();
      
      // ตรวจสอบว่ามีเพียงตัวเลขและจุดทศนิยมเท่านั้น
      const latPattern = /^-?\d+(\.\d+)?$/;
      const lonPattern = /^-?\d+(\.\d+)?$/;

      if (!latPattern.test(this.Latitude)) {
        this.errorForm.push('กรุณากรอกละติจูดเป็นตัวเลขและไม่มีช่องว่าง');
      }

      if (!lonPattern.test(this.Longitude)) {
        this.errorForm.push('กรุณากรอกลองจิจูดเป็นตัวเลขและไม่มีช่องว่าง');
      }
    },
    async handleSubmit() {
      if (!this.isFormValid()) {
        // ถ้ามี error สามารถแสดง errorForm ที่เก็บไว้ได้
        return;
      }
      this.showModal = true; // แสดง modal ถ้าฟอร์มถูกต้อง
    },
    closeModal() {
      this.showModal = false;  // ปิด modal
    },
    confirmSubmit() {
      // ทำการบันทึกข้อมูล
      this.showModal = false;  // ปิด modal
      this.isSubmitting = true; // แสดง modal โหลด
      this.submitForm(); // ส่งฟอร์มต่อไป
    },
    closeNotification() {
      this.showNotification = false; // ปิด modal แจ้งเตือน
    },
    
    // ฟังก์ชันสำหรับบันทึกข้อมูลจริง
    async submitForm() {
      try {
        const formData = new FormData();

        
        // เพิ่มข้อมูลฟอร์มลงใน FormData
        formData.append('userId', this.userId);
        formData.append('PropertyType', this.PropertyType);
        formData.append('Category', this.isOtherSelected ? this.otherCategory : this.Category);
        formData.append('LocationData', JSON.stringify(this.locationData));
        formData.append('Title', this.Title);
        formData.append('Detail', this.Detail);
        formData.append('Scale', JSON.stringify(this.Scale));
        formData.append('Price', this.Price);
        formData.append('Position', this.Position);
        if (this.telValue) {
          formData.append('Telnum', this.telValue);
        } else {
          formData.append('Telnum', this.Telnum);
        }
        if (this.lineValue) {
          formData.append('Line', this.lineValue);
        } else {
          formData.append('Line', this.Line);
        }
        formData.append('Latitude', this.Latitude);
        formData.append('Longitude', this.Longitude);
        formData.append('SelectedCard', this.selectedCard);
        // เพิ่มรูปภาพลงใน FormData
        const imageInput = document.getElementById('imageInput');
        const files = imageInput.files;

        for (let i = 0; i < files.length; i++) {
          if (files[i].size > 2 * 1024 * 1024) {
            alert('ไฟล์ ' + files[i].name + ' มีขนาดใหญ่เกิน 2MB');
            continue;
          }
          formData.append('images', files[i]);
        }

        // แปลง facilities เป็น array ของหัวข้อที่มีค่าเป็น true
        const selectedFacilities = Object.keys(this.facilities).filter(key => this.facilities[key] === true);

        // ถ้าไม่ใช่ "ที่ดิน" ให้เพิ่ม houseDetails และ facilities ลงใน formData
        if (this.Category !== 'ที่ดิน') {
          formData.append('houseDetails', JSON.stringify(this.houseDetails));
          formData.append('facilities', JSON.stringify(selectedFacilities));
        }
        console.log(formData)
        const response = await axios.post('/api/property/add', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        // การตอบสนองหลังจากบันทึกสำเร็จหรือไม่สำเร็จ
        if (response.status === 201) {
          console.log('Property added:', response.data.property);
          this.isSubmitting = false;
          this.isSuccess = true;
          this.showNotification = true;
          setTimeout(() => {
            this.isSubmitting = false;
            this.showNotification = true;
            this.$router.push({ path: '/dashboardPage', query: { component: 'ListProperty' } });
          }, 3000);
        } else {
          this.isSuccess = false;
          this.showNotification = true;
          this.isSubmitting = false;
        }
      } catch (error) {
        console.error('Error submitting form:', error);
        this.isSuccess = false;
        this.showNotification = true;
        this.isSubmitting = false;

        // แสดงข้อความจาก API บนหน้าเว็บ
        if (error.response && error.response.data && error.response.data.message) {
          this.notificationMessage = error.response.data.message; // ข้อความ error จาก API
        } else {
          this.notificationMessage = 'เกิดข้อผิดพลาดในการบันทึกข้อมูล กรุณาลองใหม่ภายหลัง';
        }
        this.isSubmitting = false;
        this.notificationMessage = '';
      }
    },
    selectCategory(category) {
      this.Category = category;
      if (category === 'อื่น ๆ') {
        this.isOtherSelected = true;
      } else {
        this.isOtherSelected = false;
      }
    },
    removeImage(index) {
      this.imagePreviews.splice(index, 1);
      this.$refs.imageInput.value = '';
    },
    selectCard(value) {
      this.selectedCard = value;
    },
    updatePrice(event) {
      // ลบเครื่องหมายคอมมาออกแล้วบันทึกค่าจริงในตัวแปร Price
      const value = event.target.value.replace(/,/g, '');
      this.Price = value ? Number(value) : '';
    },
    validateInteger(field, event) {
      let value = event.target.value;

      // ตรวจสอบว่าค่าเป็นจำนวนเต็มหรือไม่
      if (value.includes('.')) {
        value = Math.floor(value); // ถ้าเป็นทศนิยม ให้ปัดเศษลงเป็นจำนวนเต็ม
      }
      // ตรวจสอบว่ามีเลข 0 นำหน้าหรือไม่
      if (value.length > 1 && value.startsWith('0')) {
        value = parseInt(value, 10); // แปลงค่าเป็นจำนวนเต็มเพื่อตัดเลข 0 นำหน้า
      }
      // ตั้งค่ากลับไปที่ data โดยตรง
      this.houseDetails[field] = value;
      event.target.value = value; // ตั้งค่าให้ input แสดงค่าเป็นจำนวนเต็ม
    },
    confirmLeave() {
      return window.confirm('คุณแน่ใจที่จะออกจากหน้านี้หรือไม่?');
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.confirmLeave()) {
      next({ path: '/dashboardPage', query: { component: 'ListProperty' } });
    } else {
      next(false); // ยกเลิกการนำทาง
    }
  },
};
</script>
  
  <style scoped>
  .exclusive-badge-right {
    background-color: #efbf04;
    color: white;
    padding: 15px 15px;
    font-weight: bold;
    font-size: larger;
    border-radius: 5px;
    z-index: 1;
  }
  .card-container {
    transition: border-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease;
  }
  .selected-card {
    border-color: #2d1f65 !important;
    box-shadow: 0 8px 8px rgba(45, 31, 101, 0.5) !important;
  }
  .card-container:hover {
    transform: scale(1.03); /* ขยายเล็กน้อยเมื่อ hover */
    cursor: pointer; /* เปลี่ยนเคอร์เซอร์เป็นมือ */
  }
  .card img {
    max-height: 150px;
    object-fit: cover;
  }
  .no-padding-left .btn {
    padding-left: 0 !important;
  }
input::placeholder,
textarea::placeholder {
  color: lightgray; /* หรือคุณสามารถใช้ #f8f9fa หรือโทนสีอื่น ๆ ตามที่ต้องการ */
  opacity: 1; /* ปรับให้ placeholder ไม่จางเกินไป */
}

.modal {
    font-family: 'Prompt', sans-serif;
}
.modal-content {
  border: none; /* ลบเส้นขอบ */
  box-shadow: none; /* ลบเงา */
}

/* สำหรับ Backdrop */
.backdrop-enter-active,
.backdrop-leave-active {
  transition: opacity 0.3s ease;
}
.backdrop-enter-from,
.backdrop-leave-to {
  opacity: 0;
}

/* สำหรับ Modal */
.modal-enter-active,
.modal-leave-active {
  transition: all 0.3s ease;
}
.modal-enter-from,
.modal-leave-to {
  opacity: 0;
  transform: translateY(-50px);
}

/* ปรับขนาด Modal สำหรับหน้าจอคอมพิวเตอร์ */
.custom-modal-dialog {
  max-width: 600px; 
}
.set-position {
  margin-right: 1rem;
}

/* เพิ่มไอคอนและเอฟเฟกต์ให้ modal */
.bi-check-circle-fill {
  font-size: 2rem;
}
.bi-x-circle-fill {
  font-size: 2rem;
}
.checkmark-svg {
  transform: scale(0); /* เริ่มจากขนาดเล็ก */
  opacity: 0;
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.modal-enter-active .checkmark-svg {
  transform: scale(1); /* ขยายขึ้นสู่ขนาดปกติ */
  opacity: 1;
}

.circle {
  stroke-dasharray: 391;
  stroke-dashoffset: 391;
  animation: draw-circle 0.5s forwards;
}
.check {
  stroke-dasharray: 200;
  stroke-dashoffset: 200;
  animation: draw-check 0.5s forwards 0.5s;
}
@keyframes draw-circle {
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes draw-check {
  to {
    stroke-dashoffset: 0;
  }
}

:deep(.circle) {
  stroke-dasharray: 391;
  stroke-dashoffset: 391;
  animation: draw-circle 0.5s forwards;
}

:deep(.line1),
:deep(.line2) {
  stroke-dasharray: 85;
  stroke-dashoffset: 85;
  animation: draw-lines 0.5s forwards 0.5s;
}

@keyframes draw-circle {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes draw-lines {
  to {
    stroke-dashoffset: 0;
  }
}

.custom-modal-content svg {
  max-width: 120px;
  max-height: 120px;
}
/* ปรับขนาด Modal สำหรับมือถือ */
@media (max-width: 768px) { /* ขยายขอบเขตจาก 576px เป็น 768px */
  
}
  </style>