<template>
    <transition name="fade">
      <button v-show="showButton" class="btn btn-lg custom-btn btn-lg-square back-to-top" @click="scrollToTop">
        <i class="bi bi-arrow-up"></i>
    </button>
    </transition>
</template>

<script>
export default {
  data() {
    return {
      showButton: false
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      this.showButton = window.scrollY > 300
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>

<style scoped>
.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  z-index: 999;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.custom-btn {
    color: #ffffff; /* เปลี่ยนสีข้อความ */
    background-color: #2d1f65;
}
.custom-btn:hover {
    background-color: #7f5fff; /* เปลี่ยนสีพื้นหลังเมื่อ hover */
    color: white; /* เปลี่ยนสีข้อความเมื่อ hover */
}
</style>