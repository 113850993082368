<template>
  <div  class="container bg-white rounded shadow p-4" style="border: 1px solid #2d1f65;">
    <section class="sect">
      <div class="mt-5">
        <h3>ร้านค้าโปรโมชัน</h3>
      </div>
      <div class="container px-md-5 my-5"> <!-- เพิ่ม px-md-5 เพื่อให้มี padding สำหรับหน้าจอขนาด medium ขึ้นไป -->
        <form @submit.prevent="handleSubmit">
          <div class="mb-5 row">
            <div class="col-12 col-sm-6 col-md-6" v-for="(card, index) in cards" :key="index" @click="selectCard(card.value)">
              <div class="card-container rounded shadow p-4 my-3 position-relative" style="border: 2px solid #198754;" :class="{ 'selected-card': selectedCard === card.value }">
                <!-- Ribbon -->
                <div class="ribbon-wrapper">
                  <div class="ribbon">โปรโมชัน</div>
                </div>
                
                <!-- Card Content -->
                <h3 class="text-success mb-0"><strong>{{ card.title }}</strong></h3>
                <h5 class="text-success mb-0">{{ card.titleDesc }}</h5>
                <div v-if="card.offer3" class="my-4">
                  <div class="exclusive-badge my-1">{{ card.offer1 }}</div>
                  <div class="premium-badge my-1">{{ card.offer2 }}</div>
                  <div class="hot-badge my-1">{{ card.offer3 }}</div>
                </div>
                <div v-else class="my-4">
                  <div class="premium-badge my-1">{{ card.offer1 }}</div>
                  <div class="hot-badge my-1">{{ card.offer2 }}</div>
                </div>
                <h5 class="text-danger mb-1">ปกติ <del>{{ card.description }}</del></h5>
                <h4 class="text-success mb-0"><strong>เหลือ {{ card.description2 }}</strong></h4>
              </div>
            </div>
          </div>
          <!-- ปุ่มยืนยัน -->
          <div class="mb-3 row">
            <div class="text-center">
              <button type="submit" class="btn btn-success">Buy Promotion</button>
            </div>
          </div>
          
        </form>
        <teleport to="body">
          <!-- Backdrop -->
          <transition name="backdrop">
            <div v-if="showModal" class="modal-backdrop fade show" @click="showModal = false"></div>
          </transition>
          <!-- Modal -->
          <transition name="modal">
            <div v-if="showModal" class="modal fade show" tabindex="-1"  role="dialog" style="display: block;">
              <div class="modal-dialog modal-dialog-centered custom-modal-dialog" role="document">
                <div class="modal-content custom-modal-content">
                  <!-- Modal Header -->
                  <div class="modal-header">
                    <div class="mx-auto">
                      <h5 class="modal-title p-2">ยืนยันการซื้อ</h5>
                      <button type="button" class="btn-close" @click="showModal = false" style="position: absolute; right: 20px; top: 20px;"></button>
                    </div>
                  </div>
                  <!-- Modal Body -->
                  <div class="modal-body d-flex align-items-center justify-content-center">
                    <div class="text-center">
                      <div class="p-4">
                        <h6>คุณต้องการยืนยันการซื้อหรือไม่?</h6>
                      </div>
                    </div>
                  </div>
                  <!-- Modal Footer -->
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="showModal = false">ยกเลิก</button>
                    <button type="button" class="btn btn-primary" @click="confirmSubmit">ยืนยัน</button>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </teleport>

        <!-- Modal สำหรับแจ้งเตือนการบันทึกสำเร็จหรือไม่สำเร็จ -->
        <teleport to="body">
          <transition name="backdrop">
            <div v-if="showNotification" class="modal-backdrop fade show"></div>
          </transition>
          <transition name="modal">
            <div v-if="showNotification" class="modal fade show" tabindex="-1" role="dialog" style="display: block;">
              <div class="modal-dialog modal-dialog-centered custom-modal-dialog" role="document">
                <div class="modal-content custom-modal-content">
                  <div class="modal-header">
                    <div class="mx-auto">
                      <div class="modal-body p-2" v-if="isSuccess">
                        <div class="mx-auto">
                          <div class="col-8 mx-auto text-center">
                            <svg
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 131 131"
                              width="100%"
                              height="100%" 
                            >
                              <circle
                                class="circle"
                                fill="none"
                                stroke="#198754"
                                stroke-width="6"
                                stroke-miterlimit="10"
                                cx="65.1"
                                cy="65.1"
                                r="62.1"
                              />
                              <polyline
                                class="check"
                                fill="none"
                                stroke="#198754"
                                stroke-width="6"
                                stroke-linecap="round"
                                stroke-miterlimit="10"
                                points="100.2,40.2 51.5,88.8 29.8,67.5 "
                              />
                            </svg>  
                          </div>
                        </div>
                        <h3 class="text-success mx-auto mt-3">ดำเนินการซื้อสำเร็จ</h3> 
                      </div>
                      <div class="modal-body p-2" v-if="!isSuccess">
                        <div class="mx-auto">
                          <div class="col-8 mx-auto text-center">
                            <svg
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 131 131"
                              width="100%"
                              height="100%"
                            >
                              <circle
                                class="circle"
                                fill="none"
                                stroke="#db3646"
                                stroke-width="6"
                                stroke-miterlimit="10"
                                cx="65.1"
                                cy="65.1"
                                r="62.1"
                              />
                              <line
                                class="line1"
                                fill="none"
                                stroke="#db3646"
                                stroke-width="6"
                                stroke-linecap="round"
                                stroke-miterlimit="10"
                                x1="34.4"
                                y1="37.9"
                                x2="95.8"
                                y2="92.3"
                              />
                              <line
                                class="line2"
                                fill="none"
                                stroke="#db3646"
                                stroke-width="6"
                                stroke-linecap="round"
                                stroke-miterlimit="10"
                                x1="95.8"
                                y1="38"
                                x2="34.4"
                                y2="92.2"
                              />
                            </svg>
                          </div>
                        </div>
                        <h3 class="text-danger text-center mt-3">ดำเนินการซื้อล้มเหลว</h3> 
                      </div>
                      <button type="button" class="btn-close" v-if="!isSuccess" @click="closeNotification" style="position: absolute; right: 20px; top: 20px;"></button>
                    </div>
                  </div>
                  <div class="modal-body d-flex align-items-center justify-content-center">
                    <div class="text-center">
                      <h5 v-if="isSuccess" class="text-success">เครดิตคงเหลือ {{ this.remainingCredit }}</h5>
                      <h6 v-if="notificationMessage" class="text-danger">{{ this.notificationMessage }}</h6>
                      <div v-if="!isSuccess && !notificationMessage" class="text-danger">
                        <h6>กรุณาลองอีกครั้งในภายหลัง</h6>
                        <h6>หากพบปัญหากรุณาติดต่อแอดมิน</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </teleport>
        <teleport to="body">
          <transition name="backdrop">
            <div v-if="isSubmitting" class="modal-backdrop fade show"></div>
          </transition>
          <transition name="modal">
            <div v-if="isSubmitting" class="modal fade show" tabindex="-1" role="dialog" style="display: block;">
              <div class="modal-dialog modal-dialog-centered custom-modal-dialog" role="document">
                <div class="modal-content custom-modal-content">
                  <div class="modal-body d-flex align-items-center justify-content-center">
                    <div class="text-center">
                      <!-- แสดงอนิเมชันโหลดจาก Bootstrap -->
                      <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                      <!-- แสดงข้อความกำลังบันทึก -->
                      <div class="mt-3">
                        <h5>กำลังดำเนินการ...</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </teleport>
        <!-- แสดงข้อความ Alert ถ้ามี -->
        <div v-if="alert.show" :class="`alert alert-${alert.type}`">
          {{ alert.message }}
        </div>
      </div>
    </section>
  </div>
</template>

  
<script>
import { mapGetters } from 'vuex';
import axios from '@/axios.js';

export default {
  name: 'ShopPage',
  data() {
    return {
      Title: '',
      selectedCard: null,
      qrCodeUrl: null,
      file: null,    // ไฟล์สลิปที่ผู้ใช้อัปโหลด
      slipStatus: '', // สถานะการตรวจสอบสลิป
      paymentSuccess: false,
      cards: [
        { packageName: "lite", title: "Lite Pack", titleDesc: "แพ็คเล็กสบายกระเป๋า!", offer1: "PREMIUM 1 ครั้ง", offer2: "HOT 1 ครั้ง", value: "249", description: "348 Credit", description2: "249 Credit" },
        { packageName: "pro", title: "Pro Pack", titleDesc: "แพ็คมือโปรช่วยประหยัด!", offer1: "PREMIUM 3 ครั้ง", offer2: "HOT 5 ครั้ง", value: "745", description: "1242 Credit", description2: "745 Credit" },
        { packageName: "supreme", title: "Supreme Pack", titleDesc: "แพ็คซูพรีมสุดคุ้ม!", offer1: "EXCLUSIVE 1 ครั้ง", offer2: "PREMIUM 5 ครั้ง", offer3: "HOT 10 ครั้ง", value: "1599", description: "3234 Credit", description2: "1599 Credit" },
      ],
      errorForm: '',
      alert: {
        show: false,
        type: 'info',
        message: ''
      },
      showNotification: false, // สำหรับแจ้งเตือนการบันทึกสำเร็จหรือไม่
      isSuccess: false,  // เช็คสถานะว่าบันทึกสำเร็จหรือไม่
      isSubmitting: false,
      showModal: false,
      notificationMessage: '',
      remainingCredit: null,
    };
  },
  computed: {
    // ฟอร์แมตราคาให้มีคอมม่า
    formattedPrice() {
      if (!this.Price) return '';  // ถ้าไม่มีค่า ให้แสดงเป็นค่าว่าง
      return Number(this.Price).toLocaleString();  // ใส่คอมม่าในตัวเลข
    },
    displayCategory() {
      return this.Category ? this.Category : 'หมวดหมู่';
    },
    ...mapGetters(['isLoggedIn', 'userId', 'userName', 'message', 'sessionExpired']),
    myCredit() {
      return this.$store.state.credit;
    }
  },
  methods: {
    selectCard(value) {
      this.selectedCard = value;
      this.errorForm = '';
    },
    // checkSelected(){
    //   if(!this.qrCodeUrl) this.errorForm = 'กรุณาเลือกจำนวนเงินที่ต้องการ';
    // },
    showAlert(type, message, duration = 3000) {
      this.alert = {
        show: true,
        type,
        message
      };
      
      setTimeout(() => {
        this.alert.show = false;
      }, duration);
    },
    closeModal() {
      this.showModal = false;  // ปิด modal
    },
    confirmSubmit() {
      // ทำการบันทึกข้อมูล
      this.showModal = false;  // ปิด modal
      this.isSubmitting = true; // แสดง modal โหลด
      this.submitForm(); // ส่งฟอร์มต่อไป
    },
    closeNotification() {
      this.showNotification = false; // ปิด modal แจ้งเตือน
    },
    async handleSubmit() {
      if (!this.selectedCard) {
        this.showAlert('warning', 'กรุณาเลือกโปรโมชันก่อนทำการสั่งซื้อ');
        return;
      }
      this.showModal = true; // แสดง modal ถ้าฟอร์มถูกต้อง
    },
    async submitForm() {
      try {
        const selectedPackage = this.cards.find(card => card.value === this.selectedCard);
        
        const response = await axios.post('/api/shop/purchase', {
          packageName: selectedPackage.packageName,
          price: selectedPackage.value
        });

        // ถ้าสำเร็จ
        if (response.status === 200) {
          this.isSubmitting = false;
          this.showNotification = true;
          this.isSuccess = true;
          this.remainingCredit = response.data.remainingCredit;
          // อัพเดท credit ใน store (ถ้าใช้ Vuex)
          if (this.$store) {
            this.$store.commit('setCredit', response.data.remainingCredit);
          }
        }
      } catch (error) {
        let errorMessage = 'เกิดข้อผิดพลาดในการทำรายการ'
        this.notificationMessage = errorMessage;
        this.isSubmitting = false;
        this.showNotification = true;
        this.isSuccess = false;
        // จัดการ error messages ต่างๆ
        if (error.response) {
          switch (error.response.status) {
            case 400:
              if (error.response.data.error === 'Insufficient credit') {
                this.notificationMessage = `เครดิตไม่เพียงพอ (ต้องการ ${error.response.data.required} Credit)`;
              }
              break;
            case 401:
              this.notificationMessage = 'Session หมดอายุ กรุณาเข้าสู่ระบบใหม่';
              // อาจจะ redirect ไปหน้า login
              this.$router.push('/');
              break;
            case 403:
              this.notificationMessage = 'ไม่มีสิทธิ์ในการทำรายการนี้';
              break;
            default:
              this.notificationMessage = error.response.data.message || errorMessage;
          }
        }
      } finally {
        // reset all parameter after end process
        setTimeout(() => {
          this.showNotification = false;
          this.isSubmitting = false;
          this.isSuccess = false;
          this.showModal = false;
          this.notificationMessage = '';
          this.remainingCredit = null;
          this.selectedCard = null;
        }, 3000);
        
      }
    }
  },
};
</script>
  
  <style scoped>
  .exclusive-badge {
    background-color: #efbf04;
    border: 3px solid #c49e09;
    color: white;
    padding: 15px 50px;
    font-weight: bold;
    font-size: larger;
    border-radius: 5px;
    z-index: 1;
  }
  .premium-badge {
    background-color: #4621e7;
    border: 3px solid #310bd8;
    color: white;
    padding: 15px 50px;
    font-weight: bold;
    font-size: larger;
    border-radius: 5px;
    z-index: 1;
  }
  .hot-badge {
    background-color: #e73e20;
    border: 3px solid #c92a0d;
    color: white;
    padding: 15px 50px;
    font-weight: bold;
    font-size: larger;
    border-radius: 5px;
    z-index: 1;
  }
  .card-container {
    transition: border-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease;
    background-color: white
  }
  .selected-card {
    color: white; /* เปลี่ยนสีข้อความ */
    background-color: #198754; /* เปลี่ยนสีขอบ */
    .exclusive-badge, .hot-badge, .premium-badge {
      background-color: #198754;
      border: 3px solid white;
    }
  }
  .selected-card h3, .selected-card h4, .selected-card h5, .selected-card h6, .selected-card span {
    color: white!important;
}
  .card-container:hover {
    transform: scale(1.03); /* ขยายเล็กน้อยเมื่อ hover */
    cursor: pointer; /* เปลี่ยนเคอร์เซอร์เป็นมือ */
    border-color: #2d1f65 !important;
    box-shadow: 0 8px 8px rgba(45, 31, 101, 0.747) !important;
  }
  .upload-section {
    margin-top: 20px;
  }

  @media (max-width: 768px) {
    .card-container {
      margin-bottom: 20px;
    }
    .upload-section input {
      width: 100%;
    }
  }

  /* ribbon */
  .card-container {
  overflow: hidden;  /* สำคัญมากเพื่อซ่อนส่วนเกินของ ribbon */
}

.ribbon-wrapper {
  width: 85px;
  height: 88px;
  overflow: hidden;
  position: absolute;
  top: -3px;
  left: -3px;
}

.ribbon {
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  width: 120px;
  display: block;
  background: #79A70A;
  background: linear-gradient(#9BC90D 0%, #79A70A 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 19px;
  left: -26px;
}

.ribbon:before {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid #79A70A;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #79A70A;
}

.ribbon:after {
  content: "";
  position: absolute;
  right: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid #79A70A;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #79A70A;
}

.modal {
    font-family: 'Prompt', sans-serif;
}
.modal-content {
  border: none; /* ลบเส้นขอบ */
  box-shadow: none; /* ลบเงา */
}

/* สำหรับ Backdrop */
.backdrop-enter-active,
.backdrop-leave-active {
  transition: opacity 0.3s ease;
}
.backdrop-enter-from,
.backdrop-leave-to {
  opacity: 0;
}

/* สำหรับ Modal */
.modal-enter-active,
.modal-leave-active {
  transition: all 0.3s ease;
}
.modal-enter-from,
.modal-leave-to {
  opacity: 0;
  transform: translateY(-50px);
}

/* ปรับขนาด Modal สำหรับหน้าจอคอมพิวเตอร์ */
.custom-modal-dialog {
  max-width: 600px; 
}
.set-position {
  margin-right: 1rem;
}

/* เพิ่มไอคอนและเอฟเฟกต์ให้ modal */
.bi-check-circle-fill {
  font-size: 2rem;
}
.bi-x-circle-fill {
  font-size: 2rem;
}
.checkmark-svg {
  transform: scale(0); /* เริ่มจากขนาดเล็ก */
  opacity: 0;
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.modal-enter-active .checkmark-svg {
  transform: scale(1); /* ขยายขึ้นสู่ขนาดปกติ */
  opacity: 1;
}

.circle {
  stroke-dasharray: 391;
  stroke-dashoffset: 391;
  animation: draw-circle 0.5s forwards;
}
.check {
  stroke-dasharray: 200;
  stroke-dashoffset: 200;
  animation: draw-check 0.5s forwards 0.5s;
}
@keyframes draw-circle {
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes draw-check {
  to {
    stroke-dashoffset: 0;
  }
}

:deep(.circle) {
  stroke-dasharray: 391;
  stroke-dashoffset: 391;
  animation: draw-circle 0.5s forwards;
}

:deep(.line1),
:deep(.line2) {
  stroke-dasharray: 85;
  stroke-dashoffset: 85;
  animation: draw-lines 0.5s forwards 0.5s;
}

@keyframes draw-circle {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes draw-lines {
  to {
    stroke-dashoffset: 0;
  }
}

.custom-modal-content svg {
  max-width: 120px;
  max-height: 120px;
}
  </style>