<template>
    <div class="d-flex align-items-center justify-content-center bg-light py-4 px-3" style="min-height: 50vh;">
      <div class="card shadow-lg w-100" style="max-width: 400px;">
        <div class="card-body">
          <!-- ส่วนหัว -->
          <div class="text-center mb-3">
            <h2 class="h4 font-weight-bold text-dark">ตั้งรหัสผ่านใหม่</h2>
            <p class="text-muted small mb-4">
              กรุณากรอกรหัสผ่านใหม่ของคุณ
            </p>
          </div>
  
          <!-- ฟอร์ม -->
          <form v-if="isValidToken" @submit.prevent="handleSubmit">
            <!-- รหัสผ่านใหม่ -->
            <div class="form-floating mb-3">
              <input
                type="password"
                id="password"
                v-model="password"
                class="form-control"
                placeholder="รหัสผ่านใหม่"
                required
              />
              <label for="password">รหัสผ่านใหม่</label>
            </div>
  
            <!-- ยืนยันรหัสผ่านใหม่ -->
            <div class="form-floating mb-3">
              <input
                type="password"
                id="confirmPassword"
                v-model="confirmPassword"
                class="form-control"
                placeholder="ยืนยันรหัสผ่านใหม่"
                required
              />
              <label for="confirmPassword">ยืนยันรหัสผ่านใหม่</label>
            </div>
  
            <!-- ปุ่มส่ง -->
            <div class="d-grid">
              <button
                type="submit"
                :disabled="isLoading || !isPasswordValid"
                class="btn btn-primary"
              >
                <span v-if="isLoading">กำลังดำเนินการ...</span>
                <span v-else>ยืนยันการตั้งรหัสผ่านใหม่</span>
              </button>
            </div>
          </form>
          <!-- ข้อความแสดงเมื่อ token ไม่ถูกต้อง -->
          <div v-else class="text-center text-danger small">
            ลิงก์รีเซ็ตรหัสผ่านไม่ถูกต้องหรือหมดอายุแล้ว
          </div>
  
          <!-- ข้อความแจ้งเตือน -->
          <div
            v-if="message"
            class="mt-3 text-center"
            :class="[
              message.type === 'success' ? 'text-success' : 'text-danger',
              'small'
            ]"
          >
            {{ message.text }}
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "ResetPassword",
    data() {
      return {
        token: "",
        password: "",
        confirmPassword: "",
        isLoading: false,
        isValidToken: false,
        message: null,
      };
    },
    computed: {
      isPasswordValid() {
        return (
          this.password === this.confirmPassword &&
          this.password.length >= 8
        );
      },
    },
    async created() {
      // รับ token จาก URL
      this.token = this.$route.query.token;
      if (this.token) {
        await this.verifyToken();
      }
    },
    methods: {
      async verifyToken() {
        try {
          const response = await fetch(
            `/api/mail/verify-reset-token?token=${this.token}`
          );
          const data = await response.json();
          this.isValidToken = data.success;
          console.log(this.isValidToken)
        } catch (error) {
          this.isValidToken = false;
        }
      },
      async handleSubmit() {
        if (!this.isPasswordValid) return;
  
        try {
          this.isLoading = true;
          this.message = null;
  
          const response = await fetch("/api/mail/reset-password", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              token: this.token,
              newPassword: this.password,
            }),
          });
  
          const data = await response.json();
  
          if (data.success) {
            this.message = {
              type: "success",
              text: "รีเซ็ตรหัสผ่านสำเร็จ กำลังนำคุณไปยังหน้าหลัก...",
            };
            // รอสักครู่แล้วไปหน้า login
            setTimeout(() => {
              this.$router.push("/");
            }, 2000);
          } else {
            throw new Error(data.message);
          }
        } catch (error) {
          this.message = {
            type: "error",
            text: error.message || "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง",
          };
        } finally {
          this.isLoading = false;
        }
      },
    },
  };
  </script>
  
  <style scoped>
  /* ลดพื้นที่ว่างรอบ card */
  body {
    background-color: #f8f9fa;
    margin: 0;
    padding: 0;
  }
  
  .card {
    border-radius: 12px;
  }
  
  .btn-primary {
    background-color: #0d6efd;
    border: none;
  }
  
  .btn-primary:hover {
    background-color: #084298;
  }
  
  .text-muted {
    font-size: 0.875rem;
  }
  </style>
  