<template>
  <div  class="container bg-white rounded shadow p-4" style="border: 1px solid #2d1f65;" ref="mainContent">
    <section class="sect">
      <div v-if="!qrCodeUrl" class="mt-5">
        <h3>เติมเครดิต</h3>
      </div>
      <div class="container px-md-5 my-5"> <!-- เพิ่ม px-md-5 เพื่อให้มี padding สำหรับหน้าจอขนาด medium ขึ้นไป -->
        <form v-if="!qrCodeUrl" @submit.prevent="handleSubmit">
          <div class="mb-5 row">
            <div class="col-12 col-sm-6 col-md-4" v-for="(card, index) in cards" :key="index" @click="selectCard(card.value)">
              
              
              <div class="card-container rounded shadow p-4 my-3 position-relative" style="border: 2px solid #198754;" :class="{ 'selected-card': selectedCard === card.value }">
                <!-- Ribbon -->
                <!-- <div v-if="card.value >= 500" class="ribbon-wrapper">
                  <div class="ribbon">สุดคุ้ม</div>
                </div> -->
                <div v-if="card.value == 300" class="ribbon-wrapper-hot">
                  <div class="ribbon-hot">นิยม</div>
                </div>
                <div v-if="card.value >= 300" class="ribbon-wrapper">
                  <div class="ribbon-red">จำกัดเวลา</div>
                </div>
                <!-- Card Content -->
                <h5 style="color: #41c587;"><b>{{ card.label }} </b></h5>
                <!-- <div class="price-badge my-1">{{ card.label }}</div> -->
                <h6 class="text-primary my-2"><b>ได้รับ</b></h6>
                <div class="get-badge my-1">{{ card.label2 }}</div>
                <!-- <h5 class="text-danger">{{ card.label2 }}</h5> -->
                <span class="text-success"><b>{{ card.description }}</b></span>
              </div>
            </div>
          </div>
          <!-- ปุ่มยืนยัน -->
          <div class="mb-3 row">
            <div class="text-center">
              <button type="submit" class="btn btn-success">ยืนยัน</button>
            </div>
          </div>
          
        </form>
        <!-- QR Code -->
        <div v-if="qrCodeUrl">
          <h3 class="text-white">กรุณาสแกน QR Code เพื่อชำระเงิน</h3>
          <div class="d-flex justify-content-center">
            <div class="card col-12 col-md-8 mx-auto my-5">
              <div class="card-body">
                <div class="row">
                  <!-- โลโก้ PromptPay -->
                  <div class="col-12 text-center mt-3">
                    <img src="img/PPlogo.png" alt="PromptPay Logo" class="img-fluid" style="width: 250px;"/>
                  </div>
                  <!-- QR Code -->
                  <div class="col-12 text-center mb-3">
                    <img :src="qrCodeUrl" alt="QR Code" class="img-fluid" style="width: 300px;"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="errorForm" class="alert alert-danger">
          <ul class="d-flex flex-column align-items-center my-2">
            <li>{{ this.errorForm }}</li>
          </ul>
        </div>
        <!-- ฟอร์มสำหรับอัปโหลดสลิปหลังจากชำระเงิน -->
        <div v-if="qrCodeUrl" class="upload-section">
          <button @click="toSendingSlip" class="btn btn-success me-2">หน้าส่งสลิป</button>
          <button @click="toTopup" class="btn btn-secondary">ย้อนกลับ</button>
        </div>
      </div>
    </section>
  </div>
</template>

  
<script>
import { mapGetters } from 'vuex';


export default {
  name: 'CreditTopup',
  // props: ['onSlipSent'],
  data() {
    return {
      Title: '',
      selectedCard: null,
      qrCodeUrl: null,
      file: null,    // ไฟล์สลิปที่ผู้ใช้อัปโหลด
      slipStatus: '', // สถานะการตรวจสอบสลิป
      paymentSuccess: false,
      cards: [
        { label: "100 B", label2: "100 Credit", value: "100", description: "รับเพิ่ม 0%" },
        { label: "200 B", label2: "220 Credit", value: "200", description: "รับเพิ่ม 10%" },
        { label: "300 B", label2: "450 Credit", value: "300", description: "รับเพิ่ม 50%" },
        { label: "1000 B", label2: "1800 Credit", value: "1000", description: "รับเพิ่ม 80%" },
        { label: "2000 B", label2: "3800 Credit", value: "2000", description: "รับเพิ่ม 90%" },
        { label: "5000 B", label2: "10000 Credit", value: "5000", description: "รับเพิ่ม 100%" },
      ],
      errorForm: '',
    };
  },
  computed: {
    // ฟอร์แมตราคาให้มีคอมม่า
    formattedPrice() {
      if (!this.Price) return '';  // ถ้าไม่มีค่า ให้แสดงเป็นค่าว่าง
      return Number(this.Price).toLocaleString();  // ใส่คอมม่าในตัวเลข
    },
    displayCategory() {
      return this.Category ? this.Category : 'หมวดหมู่';
    },
    ...mapGetters(['isLoggedIn', 'userId', 'userName', 'message', 'sessionExpired']),
    myCredit() {
      return this.$store.state.credit;
    }
  },
  methods: {
    selectCard(value) {
      this.selectedCard = value;
      this.errorForm = '';
    },
    scrollToMainContent() {
      const mainContent = this.$refs.mainContent;
      if (mainContent) {
          mainContent.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    },
    checkSelected(){
      if(!this.qrCodeUrl) this.errorForm = 'กรุณาเลือกจำนวนเงินที่ต้องการ';
    },
    handleFileUpload(event) {
      this.file = event.target.files[0];
    },
    async handleSubmit() {
      if (!this.selectedCard) {
        this.errorForm = 'กรุณาเลือกจำนวนเงินที่ต้องการ';
        return; // หยุดการทำงานหากยังไม่ได้เลือกการ์ด
      }
      // เรียก API เพื่อสร้าง QR code
      try {
        // เรียก API เพื่อสร้าง QR Code จาก PromptPay.io
        const qrCodeUrl = `https://promptpay.io/0639302325/${this.selectedCard}.png`;
        this.qrCodeUrl = qrCodeUrl; // แสดง QR Code ให้ผู้ใช้สแกน
        if (this.qrCodeUrl) {
          this.scrollToMainContent(); // เรียกฟังก์ชันเพื่อ scroll ไปที่ container แม่
        }
      } catch (error) {
        console.error('Error generating QR code:', error);
      }
    },
    toTopup() {
      this.qrCodeUrl = null;
    },
    toSendingSlip() {
      // เรียกฟังก์ชันที่ส่งผ่าน props เพื่อเปลี่ยนหน้า
      // this.onSlipSent();
      this.$router.push({ name: 'DashboardPage', query: { component: 'SendingSlip' } });
    },
  },
};
</script>
  
  <style scoped>
  .card-container {
    transition: border-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease;
    background-color: white
  }
  .selected-card {
    color: white; /* เปลี่ยนสีข้อความ */
    background-color: #198754; /* เปลี่ยนสีขอบ */
    .get-badge {
      background-color: #198754;
      border: 2px solid white;
    }
  }
  .selected-card h5, .selected-card h6, .selected-card span {
    color: white!important;
  }
  .card-container:hover {
    transform: scale(1.03); /* ขยายเล็กน้อยเมื่อ hover */
    cursor: pointer; /* เปลี่ยนเคอร์เซอร์เป็นมือ */
    border-color: #2d1f65 !important;
    box-shadow: 0 8px 8px rgba(45, 31, 101, 0.747) !important;
  }
  .upload-section {
    margin-top: 20px;
  }

  @media (max-width: 768px) {
    .card-container {
      margin-bottom: 20px;
    }
    .upload-section input {
      width: 100%;
    }
  }
  .get-badge {
    background-color: #efbf04;
    border: 2px solid #c49e09;
    color: white;
    padding: 5px 5px;
    font-weight: bold;
    font-size: larger;
    border-radius: 5px;
    z-index: 1;
  }

  .price-badge {
    background-color: #41c587;
    border: 3px solid #198754;
    color: white;
    padding: 5px 5px;
    font-weight: bold;
    font-size: larger;
    border-radius: 5px;
    z-index: 1;
  }

  /* ribbon */
  .card-container {
    overflow: hidden;  /* สำคัญมากเพื่อซ่อนส่วนเกินของ ribbon */
  }

  .ribbon-wrapper {
    width: 85px;
    height: 88px;
    overflow: hidden;
    position: absolute;
    top: -3px;
    left: -3px;
  }

  .ribbon {
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    width: 120px;
    display: block;
    background: #efbf04;
    background: linear-gradient(#f1c821 0%, #efbf04 100%);
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 19px;
    left: -26px;
  }

  .ribbon:before {
    content: "";
    position: absolute;
    left: 0px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid #efbf04;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #efbf04;
  }

  .ribbon:after {
    content: "";
    position: absolute;
    right: 0px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid transparent;
    border-right: 3px solid #efbf04;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #efbf04;
  }

  /* ribbob red */
  .ribbon-wrapper {
    width: 85px;
    height: 88px;
    overflow: hidden;
    position: absolute;
    top: -3px;
    left: -3px;
  }

  .ribbon-red {
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    width: 120px;
    display: block;
    background: #c92a0d;
    background: linear-gradient(#e94426 0%, #ad2108 100%);
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 19px;
    left: -26px;
  }

  .ribbon-red:before {
    content: "";
    position: absolute;
    left: 0px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid #c92a0d;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #c92a0d;
  }

  .ribbon-red:after {
    content: "";
    position: absolute;
    right: 0px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid transparent;
    border-right: 3px solid #c92a0d;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #c92a0d;
  }
  /* ribbon hot */
  .ribbon-wrapper-hot {
    width: 85px;
    height: 88px;
    overflow: hidden;
    position: absolute;
    top: -16px;
    left: -16px;
  }
  .ribbon-hot {
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    width: 120px;
    display: block;
    background: #198754;
    background: linear-gradient(#3cc786 0%, #198754 100%);
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 19px;
    left: -26px;
  }

  .ribbon-hot:before {
    content: "";
    position: absolute;
    left: 0px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid #198754;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #198754;
  }

  .ribbon-hot:after {
    content: "";
    position: absolute;
    right: 0px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid transparent;
    border-right: 3px solid #198754;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #198754;
  }
  </style>
