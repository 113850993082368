import { createStore } from 'vuex';
import axios from '@/axios';
import { getCsrfToken, resetCsrfToken } from '@/csrf';

axios.defaults.withCredentials = true;

export default createStore({
  state: {
    isLoggedIn: false,
    userId: null,
    userName: '',  // เพิ่มตัวแปรนี้เพื่อเก็บชื่อผู้ใช้
    name: '',
    tel: '',
    line: '',
    email: '',
    message: '',
    credit: null,
    Postextra: {
      premium: 0,
      hot: 0
    },
    accessToken: null,
    tokenExpiration: null,
    sessionExpired: false, // เพิ่มสถานะนี้
    error: null
  },
  mutations: {
    setLoggedIn(state, value) {
      state.isLoggedIn = value;
    },
    setUserId(state, value) {
      state.userId = value;
    },
    setUserName(state, value) {
      state.userName = value;
    },
    setName(state, value) {
      state.name = value;
    },
    setEmail(state, value) {
      state.email = value;
    },
    setTel(state, value) {
      state.tel = value;
    },
    setLine(state, value) {
      state.line = value;
    },
    setMessage(state, value) {
      state.message = value;
    },
    setError(state, value) {
      state.error = value;
    },
    setAccessToken(state, token) {
      state.accessToken = token;
    },
    setTokenExpiration(state, expirationTime) {
      state.tokenExpiration = expirationTime;
    },
    setSessionExpired(state, value) {
      state.sessionExpired = value;
    },
    setCredit(state, value) {
      state.credit = value;
    },
    setPostextra(state, value) {
      state.Postextra = value;
    },
  },
  actions: {
    async register({ commit }, { username, email, password }) {
      try {
        const response = await axios.post('/auth/register', { username, email, password });
        commit('setError', null);
        commit('setMessage', response.data.message);
      } catch (error) {
        commit('setError', error.message);
        throw error;
      }
    },
    async login({ commit }, { username, password }) {
      try {
        const response = await axios.post('/auth/login', { username, password });
        resetCsrfToken();
        // ร้องขอ CSRF token ใหม่
        await getCsrfToken();

        commit('setLoggedIn', true);
        commit('setUserId', response.data.userId);
        commit('setError', null);
        return response.data;
      } catch (error) {
        commit('setError', error.message);
        commit('setLoggedIn', false);
        throw error;
      }
    },
    async checkAuth({ commit }) {
      try {
        const response = await axios.get('/auth/check');
        commit('setLoggedIn', response.data.isLoggedIn);
        commit('setUserId', response.data.userId);
        commit('setUserName', response.data.userName);
        commit('setName', response.data.name);
        commit('setTel', response.data.tel);
        commit('setLine', response.data.line);
        commit('setEmail', response.data.email);
        commit('setCredit', response.data.credit);
        commit('setPostextra', response.data.Postextra);
      } catch (error) {
        commit('setLoggedIn', false);
        commit('setUserId', null);
        commit('setUserName', '');
        commit('setName', '');
        commit('setTel', '');
        commit('setLine', '');
        commit('setEmail', '');
        commit('setCredit', null);
        commit('setPostextra', null);
      }
    },
    async logout({ commit }) {
      try {
        await axios.post('/auth/logout');
        resetCsrfToken();
        commit('setLoggedIn', false);
        commit('setUserId', null);
        commit('setUserName', '');  // เคลียร์ userName เมื่อออกจากระบบ
        commit('setName', '');
        commit('setTel', '');
        commit('setLine', '');
        commit('setEmail', '');
        commit('setCredit', null);
        commit('setPostextra', null);
        commit('setMessage', 'Logged out successfully');
      } catch (error) {
        commit('setMessage', 'Logout failed');
        commit('setError', error.message);
      }
    },
  },
  getters: {
    isLoggedIn: state => state.isLoggedIn,
    userId: state => state.userId,
    message: state => state.message,
    error: state => state.error,
    userName: state => state.userName, // สร้าง getter ที่ต้องการ
    name: state => state.name,
    email: state => state.email,
    tel: state => state.tel,
    line: state => state.line,
    credit: state => state.credit,
    sessionExpired: state => state.sessionExpired, // เพิ่ม getter นี้
  }
});
